import React, { Component, createRef, ReactNode, RefObject } from "react";
/// <reference path="src/typings/index.d.ts" />
/// <reference path="src/typings/react-images/loaded/index.d.ts" />
import ImagesLoaded from "react-images-loaded";
import PhotoBox from "src/ui/components/PhotoBox/PhotoBox";
import "./SocialImagePost.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    post: Spintr.ISocialImagePost;
    parentPost: Spintr.ISocialPostBase;
}

interface IState {
    displayPhotoBox?: boolean;
    photoBoxSrc?: string;
    photoBoxImageId?: number;
}

class SocialImagePost extends Component<IProps, IState> {
    protected moreImagesRef: RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.state = {};

        this.moreImagesRef = createRef();

        this.onImagesLoaded = this.onImagesLoaded.bind(this);
    }

    public render() : ReactNode {
        const { post } = this.props;
        const imageCount = (post.photos || []).length;
        const stageClass = " count" + (imageCount > 4 ? 5 : imageCount);

        return(
            <div className="SocialImagePost">
                <div className={"image-stage"+stageClass}>
                    <ImagesLoaded
                        elementType="div"
                        key={imageCount.toString()} // force render when count changes
                        onAlways={this.onImagesLoaded}
                    >
                        {(this.props.post.photos||[]).slice(0, 5).map((image, index) => 
                            this.renderImage(image, index))}
                    </ImagesLoaded>
                </div>
                {
                    this.state.displayPhotoBox && (
                        <PhotoBox
                            imageUrl={this.state.photoBoxSrc}
                            id={this.state.photoBoxImageId}
                            samePostOnly={true}
                            post={this.props.parentPost}
                            onClose={() => {
                                this.setState({
                                    displayPhotoBox: false,
                                    photoBoxSrc: null,
                                    photoBoxImageId: null
                                });
                            }}
                        />
                    )
                }
            </div>
        );
    }

    protected onImagesLoaded(instance: any): void {
        const col = (imgs: HTMLImageElement[]) => imgs.reduce(
            (acc: number, obj) => 
                !acc 
                    ? obj.height 
                    : (obj.height > acc ? acc : obj.height),
            0
        );

        const imageCount = this.props.post.photos.length;
        const images: HTMLImageElement[] = instance.images.map(
            (obj: any) => (obj.img as HTMLImageElement)
        );

        const count = (imageCount === 2 ? 0 : (imageCount > 4 ? 2 : 1));
        const small = images.slice(count);

        const h2 = Math.min(Math.max((col(small) as number) - 1, 80));

        if (imageCount > 4) {
            const large = images.slice(0, 2);
            const largeHeight = col(large) - 1;

            large.forEach(img => {
                img.parentElement.setAttribute(
                    "style",
                    `height:${largeHeight}px; max-height: 238px;` +
                    `line-height:${largeHeight}px;` +
                    "background-size: cover;" +
                    `background-image: url('${img.src}');`
                );
                img.setAttribute("style", "display: none;");
            });

            if (this.moreImagesRef.current) {
                this.moreImagesRef.current.style.height = h2 + "px";
            }
        }

        if (imageCount === 2) {
            const height = 238;
            small.forEach(img => {
                img.parentElement.setAttribute("style",
                    `height: ${height}px; line-height: ${height}px; max-height: 238px;` +
                    'background-size: cover;' +
                    `background-image: url('${img.getAttribute("src")}');`
                );
                img.setAttribute("style", "display: none;");
            });
        }
        else {
            small.forEach(img => {
                img.parentElement.setAttribute("style",
                    `height: ${h2}px; line-height: ${h2}px; max-height: 238px;` +
                    'background-size: cover;' +
                    `background-image: url('${img.getAttribute("src")}');`
                );
                img.setAttribute("style", "display: none;");
            });
        }

        if ([3,4].indexOf(imageCount)>-1) {
            const bigImage = images.slice(0, 1);
            const bigSize = col(bigImage) - 1;
            const spaceLeft = 490 - h2 - 1;
            const h1 = bigSize > spaceLeft ? spaceLeft : bigSize;

            bigImage[0].parentElement.setAttribute("style", 
                `height: ${h1}px; line-height: ${h1}px; max-height: 238px;` +
                'background-size: cover;' +
                `background-image: url('${bigImage[0].getAttribute("src")}');`
            );
            bigImage[0].setAttribute("style", "display: none;");
        }
    }

    protected renderImage(
        image: Spintr.ISocialImageObject,
        index: number,
        fromInstagram: boolean = false,
    ): ReactNode {
        const classNames = ["image"];

        const imageCount = (this.props.post.photos || []).length;
        const displayPlus = index === 4 && imageCount > 5;
        if (displayPlus) {
            classNames.push("more");
        }

        return (
            <div 
                className={classNames.join(" ")}
                key={"image-post-item"+image.id}
                onClick={() => {
                    this.setState({
                        displayPhotoBox: true,
                        photoBoxImageId: image.id,
                        photoBoxSrc: image.photoUrl
                    });
                }}
            >
                {!displayPlus &&
                    <div className="maximize-icon">
                        <Visage2Icon icon="maximize-3" color="white" size="small" />
                    </div>}
                <img 
                    alt={image.description}
                    id={"image-post-item"+image.id}
                    data-id={image.id}
                    src={image.photoUrl}
                />
                {displayPlus && (
                    <div className="count">
                        <div className="plus">+</div>
                        <div className="number">{imageCount-4}</div>
                    </div>
                )}
                {fromInstagram && (
                    <div className="instagram-icon" />
                )}
            </div>
        );
    }
}

export default SocialImagePost;