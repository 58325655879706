import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import { SocialHidden } from "../SocialHidden";
import { SocialPostEdit } from "../SocialPostEdit";
import { SocialPostHeader } from "../SocialPostHeader";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SocialPostText from "../SocialPostText/SocialPostText";
import { SocialPostContent } from "../SocialPostContent";
import "./SocialPost.scss";
import SocialPostTypeContent from "../SocialPostTypeContent/SocialPostTypeContent";
import { SpintrTypes } from "src/typings";
import SocialPostShare from "../SocialPostShare/SocialPostShare";
import Visage2Composer from "src/spintr/components/Visage2Composer/Visage2Composer";
import { SocialFeedType, visage2SocialPostTypes } from "src/social-feed/types";
import classNames from "classnames";
import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Link } from "react-router-dom";
import { InteractiveTextFormatOptions } from "src/utils";

interface ISocialPostProps {
    isShare?: boolean;
    feedType?: SocialFeedType;
    post: Spintr.ISocialPostBase;
    onDoneEditing?: any;
    hidePinned?: boolean;
    hideActionMenu?: boolean;
    interactiveTextOptions?:    InteractiveTextFormatOptions | undefined;
}

interface ISocialPostState {
    isEditing: boolean;
}

class SocialPost extends Component<ISocialPostProps, ISocialPostState> {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false
        }
    }

    toggleIsEditing = () => {
        this.setState((prevState) => ({
            isEditing: !prevState.isEditing,
        }));
    }

    renderEditView(): ReactNode {
        let mainType;

        const editPost = {
            ...this.props.post,
            content: this.props.post.content.filter((c) => c.StatusType != SpintrTypes.StatusType.File),
        };

        let identity;

        for (const content of editPost.content) {
            if (content.Type == SpintrTypes.UberType.BusinessDeal) {
                content.StatusType = SpintrTypes.StatusType.BusinessDeal;
            } else if (content.Type == SpintrTypes.UberType.Kudos) {
                content.StatusType = SpintrTypes.StatusType.Kudos;
            } else if (content.Type == SpintrTypes.UberType.SomethingFun) {
                content.StatusType = SpintrTypes.StatusType.SomethingFun;

                if (content.somethingFunImageUrl) {
                    content.photos = [
                        // @ts-ignore
                        {
                            id: 0,
                            thumbnailUrl: content.somethingFunImageUrl,
                            description: "",
                            videoThumbnailUrl: "",
                        },
                    ];
                }
            } else if (content.Type == SpintrTypes.UberType.Bookmark) {
                content.BookmarkImages = [];
            } else if (content.Type === SpintrTypes.UberType.ImagePost || content.Type === SpintrTypes.UberType.Image) {
                if (!content.photos) {
                    content.Video = {
                        name: "",
                        VideoThumbnail: content.VideoThumbnail,
                    };
                } else {
                    content.photos = content.photos.map((p) => ({
                        ...p,
                        thumbnailUrl: p.photoUrl,
                    }));
                }
            } else if (content.Type === SpintrTypes.UberType.SystemStatus) {
                const systemStatusContent = content as Spintr.ISocialSystemStatus;
                systemStatusContent.StatusType = SpintrTypes.StatusType.SystemStatus;
                systemStatusContent.publishStartDate = new Date(systemStatusContent.publishStartDate);
                systemStatusContent.startDate = new Date(systemStatusContent.startDate);

                if (systemStatusContent.endDate) {
                    systemStatusContent.endDate = new Date(systemStatusContent.endDate);
                }

                identity = {
                    name: systemStatusContent.resourceName,
                    images: {
                        feedComposer: systemStatusContent.resourceImageUrl,
                    },
                };
            }

            if (content.background) {
                content.color = { from: content.background.start.color, to: content.background.stop.color };
                editPost.color = { from: content.background.start.color, to: content.background.stop.color };
            }

            const visage2Type = visage2SocialPostTypes.find((type) => content.Type == type.uberType);
            if (visage2Type) {
                mainType = visage2Type;
            }
        }

        if (editPost.background) {
            editPost.color = { from: editPost.background.start.color, to: editPost.background.stop.color };
        }

        return (
            <Visage2Composer
                type={mainType}
                post={editPost}
                identity={identity}
                onClosing={() => {
                    this.toggleIsEditing();

                    this.props.onDoneEditing?.();
                }}
                editMode
            />
        );
    }

    renderTypeContent() {
        const supportedTypes = [
            SpintrTypes.UberType.BusinessDeal,
            SpintrTypes.UberType.Kudos,
            SpintrTypes.UberType.SomethingFun,
        ];

        const child = this.props.post.content.find(x => supportedTypes.indexOf(x.Type) > -1);

        if (!child) {
            return null;
        }

        return (
            <SocialPostTypeContent post={{
                ...child,
                color: this.props.post.color
            }} />
        )
    }

    private renderContent = (isMainFeed: boolean, isSystemStatus: boolean, isSystemStatusFeed: boolean) => {
        const minimizedSystemStatus = isSystemStatus && !isSystemStatusFeed;

        if (minimizedSystemStatus) {
            return this.renderMinimizedSystemStatusPostContent();
        }

        return this.renderSocialPostContent(isMainFeed, isSystemStatus);
    }

    private renderMinimizedSystemStatusPostContent = () => {
        return (
            <div className="SocialPost">
                <SocialPostHeader
                    post={this.props.post}
                    onEditClick={this.toggleIsEditing}
                    hideActionMenu={this.props.hideActionMenu}
                    hidePinned={this.props.hidePinned}
                    feedType={this.props.feedType}
                />
                {this.state.isEditing && this.renderEditView()}
            </div>
        )
    }

    private renderSocialPostContent = (isMainFeed: boolean, isSystemStatus: boolean) => (
        <div className="SocialPost">
            {this.props.post.isPinned && (
                 <Label
                     className="pin-header"
                     color="visageGray2"
                     size="body-2"
                     weight="medium">
                    <span>
                         <Visage2Icon icon="pin" type="custom" color="visageGray2" size="small" />
                     </span>
                     <span>
                         {localize("PINNED_POST")}
                     </span>
                 </Label>
            )}
            <SocialPostHeader
                post={this.props.post}
                onEditClick={this.toggleIsEditing}
                hideActionMenu={this.props.hideActionMenu}
                hidePinned={this.props.hidePinned}
                feedType={this.props.feedType}
            />
            <div
                className={classNames("content", {
                    "is-system-status": isSystemStatus,
                    "is-main-feed": isMainFeed,
                })}
            >
                <SocialPostText post={this.props.post} interactiveTextOptions={this.props.interactiveTextOptions} />
                {this.renderTypeContent()}
                <SocialPostContent post={this.props.post} isShare />
                {this.props.post.Share && <SocialPostShare post={this.props.post} />}
                {this.state.isEditing && this.renderEditView()}
            </div>
        </div>
    )

    public render(): ReactNode {
        if (this.props.post.hidden) {
            return <SocialHidden post={this.props.post} />
        }

        const isMainFeed = this.props.feedType === SocialFeedType.Combined;
        const isSystemStatus = this.props.post.StatusType === SpintrTypes.StatusType.SystemStatus;
        const isSystemStatusFeed = this.props.feedType === SocialFeedType.SystemStatus;


        if (isSystemStatus && !isSystemStatusFeed) {
            return (
                <Link
                    to={
                        "/operations/" +
                        (
                            this.props.post.content.find(
                                (c) => c.Type === SpintrTypes.UberType.SystemStatus
                            ) as Spintr.ISocialSystemStatus
                        ).resourceId
                    }
                >
                    {this.renderContent(isMainFeed, isSystemStatus, isSystemStatusFeed)}
                </Link>
            );
        }

        return this.renderContent(isMainFeed, isSystemStatus, isSystemStatusFeed);
    }
}

export default SocialPost;