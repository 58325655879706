import { AxiosRequestConfig, CancelToken } from "axios";
import api from "src/spintr/SpintrApi";
import { getRouteAsync } from "./routesApi";
import { SpintrTypes } from "src/typings";

const baseUrl = "/api/v1/wikis";

export async function queryPrioritizedWikis(
    includeDeleted: boolean,
    cancelToken?: CancelToken | undefined
): Promise<Spintr.IWiki[]> {
    const reqConfig: AxiosRequestConfig = {
        params: {
            take: 20,
            includeDeleted,
            orderByColumn: "Name",
            isAscending: true,
            onlyPrioritized: true,
        },
        cancelToken,
    };
    
    const response = await api.get<Spintr.QueryEnvelope<Spintr.IWiki>>(
        `${baseUrl}/pages`,
        reqConfig
    );

    return response.data.items;
};

export async function queryWikis(
    offset:             number,
    limit:              number,
    orderBy:            "Name" | "LastChange",
    ascending:          boolean,
    searchText:         string,
    includeDeleted?:    boolean,
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.QueryEnvelope<Spintr.IWiki>> {
    const reqConfig: AxiosRequestConfig = {
        params: {
            skip: offset,
            take: limit,
            orderByColumn: orderBy,
            isAscending: ascending,
            searchText,
            includeDeleted,
        },
        cancelToken,
    };
    
    const response = await api.get<Spintr.QueryEnvelope<Spintr.IWiki>>(
        baseUrl,
        reqConfig
    );

    return response.data;
}

export async function queryArticlesAsync(
    wikiId:             number,
    offset:             number,
    limit:              number,
    orderBy:            "Name" | "ArticleCount",
    ascending:          boolean,
    searchText:         string,
    includeDeleted?:    boolean,
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.IWikiArticlesEnvelope> {
    const reqConfig: AxiosRequestConfig = {
        params: {
            skip: offset,
            take: limit,
            orderByColumn: orderBy,
            isAscending: ascending,
            searchText,
            includeDeleted,
        },
        cancelToken,
    };
    
    const response = await api.get<Spintr.IWikiArticlesEnvelope>(
        `${baseUrl}/pages/${wikiId}`,
        reqConfig
    );

    return response.data;
}

export async function queryWikiArticlesAsync(
    wikiId:             number,
    searchText?:        string | undefined,
    offset?:            number | undefined,
    limit?:             number | undefined,
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.WikiArticle[]> {
    const reqConfig: AxiosRequestConfig = {
        params: {
            skip: offset,
            take: limit,
            searchText,
        },
        cancelToken,
    };
    
    const response = await api.get<Spintr.WikiArticle[]>(
        `${baseUrl}/articles/${wikiId}`,
        reqConfig
    );

    return response.data;
}

export async function findWikiByRouteAsync(
    wikiName:           string,
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.IWiki2> {
    const route = wikiName.indexOf("/") !== -1
        ? wikiName
        : `wikis/${wikiName}`;
    
    const data = await getRouteAsync<Spintr.IWiki2>(
        route,
        cancelToken,
    );

    return data;
}

export async function fetchRecentlyVisitedWikis(
    source:             "me" | "team",
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.IWiki[]> {
    const reqConfig: AxiosRequestConfig = {
        cancelToken,
    };
    
    const response = await api.get<Spintr.IWiki[]>(
        `${baseUrl}/recent/${source}`,
        reqConfig
    );

    return response.data;
}

export async function fetchRecentlyVisitedWikiArticlesAsync(
    source:             "me" | "team",
    cancelToken?:       CancelToken | undefined
): Promise<Spintr.IWikiArticle[]> {
    const reqConfig: AxiosRequestConfig = {
        cancelToken,
    };
    
    const response = await api.get<Spintr.IWikiArticle[]>(
        `${baseUrl}/articles/recent/${source}`,
        reqConfig
    );

    return response.data;
}
