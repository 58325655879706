import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { ActionMenu, SpintrUser } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";

interface IProps extends RouteComponentProps {
    isAdmin: boolean;
    isEditor: boolean;
    isUserManager: boolean;
    isSmallViewMode: boolean;
    unit: IUnitUnitView;
}

interface IState {}

interface IUnitUnitView extends Spintr.IUnit {
    location?: string;
}

class OrganisationPeopleView extends Component<IProps, IState> {
    private isOffice = this.props.unit.unitType == SpintrTypes.UnitType.Office;
    private isCompany = this.props.unit.unitType == SpintrTypes.UnitType.Company;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    fetchList = (skip, take, columnId, isAscending, searchQuery) => {
        return new Promise((resolve, reject) => {
            api
                .get(`/api/v1/units/${this.props.unit.id}/people`, {
                    params: {
                        isAscending: isAscending,
                        orderByColumn: columnId,
                        searchText: searchQuery,
                        take: take,
                        skip: skip,
                    },
                })
                .then((response: AxiosResponse) => {
                    resolve({
                        data: response.data.users,
                        totalCount: response.data.totalCount,
                    });
                });
        });
    };

    render() {
        return (
            <div className="organisation-people-view">
                <SpintrList
                    fetch={this.fetchList}
                    orderByColumn="name"
                    disableSort
                    buttons={[
                        ...(this.props.isAdmin || this.props.isUserManager
                            ? [
                                  {
                                      text: localize("BjudIn"),
                                      key: "invite",
                                      iconProps: { iconName: "add" },
                                      className: "commandBarAddButton",
                                      onClick: () => {
                                          this.props.history.push({
                                              pathname: `/organisation/v/${this.props.unit.id}/invite/${this.props.unit.officeId}`,
                                          });
                                      },
                                  },
                              ]
                            : []),
                    ]}
                    onRowClick={(user) => {
                        this.props.history.push({
                            pathname: `/profile/${user.id}`,
                        });
                    }}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            onRender: (user) => (
                                <SpintrUser nameLink={`/profile/${user.id}`} name={user.name} imageUrl={user.image} personalName={true} />
                            ),
                        },
                        ...(this.isOffice ? [{
                            name: localize("Avdelning"),
                            fieldName: "departmentName",
                            minWidth: 200,
                        }] : []),
                        {
                            name: localize("LedareForAvdelning"),
                            fieldName: "isBoss",
                            onRender: (user) => (user.isBoss ? localize("Ja") : ""),
                            minWidth: 200,
                        },
                        {
                            name: localize("Roll"),
                            fieldName: "roleString",
                            minWidth: 200,
                        },
                        ...(this.props.isAdmin || this.props.isEditor
                            ? [
                                  {
                                      name: "",
                                      minWidth: 20,
                                      onRender: (user, ref) => (
                                          <ActionMenu
                                              categories={[
                                                  {
                                                      items: [
                                                          {
                                                              text: localize(
                                                                  this.isOffice
                                                                      ? user.isBoss
                                                                          ? "TaBortSomLedareForKontor"
                                                                          : "GorTillLedareForKontor"
                                                                      : user.isBoss
                                                                      ? "TaBortSomLedareForAvdelning"
                                                                      : "GorTillLedareForAvdelning"
                                                              ),
                                                              onClick: () => {
                                                                api
                                                                      .put("/api/v1/users/headof", {
                                                                          userId: user.id,
                                                                          value: !user.isBoss,
                                                                          targetType: this.isCompany ?
                                                                                SpintrTypes.UberType.Company :
                                                                                this.isOffice
                                                                                    ? SpintrTypes.UberType.Office
                                                                                    : SpintrTypes.UberType.Department,
                                                                      })
                                                                      .then(() =>
                                                                          ref.setState((prevState) => ({
                                                                              data: prevState.data.map((user2) => {
                                                                                  if (user.id == user2.id) {
                                                                                      user2.isBoss = !user2.isBoss;
                                                                                  }

                                                                                  return user2;
                                                                              }),
                                                                          }))
                                                                      );
                                                              },
                                                          },
                                                      ],
                                                  },
                                              ]}
                                          />
                                      ),
                                  },
                              ]
                            : []),
                    ]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        isSmallViewMode: state.ui.isSmallViewMode,
        isUserManager: state.profile.active.roles.includes("usermanager"),
    };
};

export default connect(mapStateToProps)(OrganisationPeopleView);
