import React, { Component } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { InteractionsBar } from "src/interactions/components";
import CommentsContainer from "src/interactions/components/CommentsContainer";
import { localize } from 'src/l10n';
import { Label, Loader, setFocusCommentFieldForUberId } from "src/ui";
import { fetchComments, fetchLikers, setComments, setLikers } from '../actions';

interface IOwnProps extends RouteComponentProps {
    uberId: number,
    comments?: Spintr.IComment[],
    likers?: Spintr.IUser[],
    onlyDisplayInteractionsBar?: boolean,
    onlyDisplayCommentsContainer?: boolean,
    interactionsBarColor?: spintrColors,
    interactionsBarCommentPreview?: Spintr.IComment,
    staticCommentCount?: number,
    useSmallerInteractionsStyle?: boolean,
    date?: Date,
    uberIsComment?: boolean,
    onCommentIconClick?: any
    identity?: any;
    sendToMixpanel?: boolean;
    enableReactions?: boolean;
    displayBigButtons?: boolean;
    displayPillButtons?: boolean;
    showLabel?: boolean;
    commentsContainerId?: string;
    displayShareButton?: boolean;
    isUnread?: boolean;
    isFeedPost?: boolean;
    targetCreatorId?: number;
    targetIsQuestion?: boolean;
    onChildReply?: any;
    hideAllComments?: boolean;
    commentPlaceholder?: string | undefined;
}

interface IStateProps {
    interactions: any;
    focusCommentFieldForUberId: number;
    allInteractions: any;
    hideCommentForm: boolean;
}

interface IState {
    displayCommentForm: boolean;
}

type Props = IOwnProps & IStateProps & DispatchProp;

class SocialBlock extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        let displayCommentForm = true;

        if (this.props.uberIsComment &&
            this.props.focusCommentFieldForUberId !== this.props.uberId) {
            displayCommentForm = false;
        }

        this.state = {
            displayCommentForm,
        };

        this.onCommentIconClick = this.onCommentIconClick.bind(this);
    }

    public init() {
        if (this.props.uberId === 0) {
            return;
        }

        if (!this.props.comments) {
            this.fetchComments();
        } else {
            this.setComments();
        }

        if (!this.props.likers) {
            this.fetchLikers();
        } else {
            this.setLikers();
        }
    }

    public componentDidMount() {
        this.init();
    }

    public componentDidUpdate = (prevProps: Props) => {
        if (this.props.uberId !== prevProps.uberId) {
            this.init();
        }

        if (!this.state.displayCommentForm &&
            this.props.focusCommentFieldForUberId &&
            this.props.focusCommentFieldForUberId === this.props.uberId) {
            this.setState({
                displayCommentForm: true,
            });
        } else if (this.props.uberIsComment &&
            this.state.displayCommentForm &&
            prevProps.focusCommentFieldForUberId !== this.props.focusCommentFieldForUberId) {
            this.setState({
                displayCommentForm: false
            });
        } else if (!this.props.uberIsComment &&
            !this.state.displayCommentForm &&
            (!prevProps.interactions || !prevProps.interactions.comments || prevProps.interactions.comments.length === 0) &&
            (!!this.props.interactions && !!this.props.interactions.comments && this.props.interactions.comments.length > 0)) {
            this.setState({
                displayCommentForm: true,
            });
        }
    };

    public render() {
        if (!this.props.interactions) {
            return null;
        }

        if (this.props.interactions.commentsIsLoading ||
            this.props.interactions.likersIsLoading) {
            return <Loader />;
        }

        let classNames = ["socialBlock"];

        if (this.props.enableReactions) {
            classNames.push("enableReactions");
        }

        if (!this.state.displayCommentForm || this.props.hideCommentForm) {
            classNames.push("noCommentForm");
        }

        return (
            <div className={classNames.join(" ")}>
                {
                    !this.props.onlyDisplayCommentsContainer && this.renderInteractionsBar()
                }
                {
                    !this.props.onlyDisplayInteractionsBar && this.renderCommentsContainer()
                }
            </div>
        )
    }

    protected fetchComments() {
        this.props.dispatch(fetchComments(this.props.uberId));
    }

    protected fetchLikers() {
        this.props.dispatch(fetchLikers(this.props.uberId));
    }

    protected onCommentIconClick(): void {
        if (this.props.uberIsComment &&
            this.props.onChildReply) {
            this.props.onChildReply();
            return;
        }

        this.setState({ displayCommentForm: true });
        this.props.dispatch(setFocusCommentFieldForUberId(undefined));
        requestAnimationFrame(() => {
            this.props.dispatch(setFocusCommentFieldForUberId(this.props.uberId));
        });

        if (this.props.onCommentIconClick) {
            this.props.onCommentIconClick();
            return;
        }

        this.props.history.push({
            pathname: `/goto/${this.props.uberId}`,
        });
    }

    protected renderCommentsContainer() {
        return (
            <CommentsContainer
                id={this.props.commentsContainerId}
                uberId={this.props.uberId}
                comments={this.props.interactions.comments}
                uberIsComment={this.props.uberIsComment}
                identity={this.props.identity}
                sendToMixpanel={this.props.sendToMixpanel}
                displayCommentForm={!this.props.hideCommentForm && this.state.displayCommentForm}
                targetCreatorId={this.props.targetCreatorId}
                targetIsQuestion={this.props.targetIsQuestion}
                hideAllComments={this.props.hideAllComments}
                formPlaceholder={this.props.commentPlaceholder}
                onChildReply={() => {
                    this.setState({ displayCommentForm: true });

                    this.props.dispatch(setFocusCommentFieldForUberId(this.props.uberId));
                }}
            />
        )
    }

    protected renderInteractionsBar() {
        return (
            <InteractionsBar
                identity={this.props.identity}
                uberId={this.props.uberId}
                comments={this.props.interactions.comments}
                likers={this.props.interactions.likers}
                color={this.props.interactionsBarColor}
                commentPreview={this.props.interactionsBarCommentPreview}
                staticCommentCount={this.props.staticCommentCount}
                date={this.props.date}
                hideComments={this.props.uberIsComment}
                uberIsComment={this.props.uberIsComment}
                onCommentClick={this.onCommentIconClick}
                sendToMixpanel={this.props.sendToMixpanel}
                enableReactions={this.props.enableReactions}
                displayBigButtons={this.props.displayBigButtons}
                displayPillButtons={this.props.displayPillButtons}
                displayShareButton={this.props.displayShareButton}
                isUnread={this.props.isUnread}
                hideCommentButton={this.props.hideCommentForm}
                commentPlaceholder={this.props.commentPlaceholder}
            />
        )
    }

    protected setComments() {
        if (this.props.interactions.comments) {
            return;
        }

        this.props.dispatch(
            setComments(this.props.uberId, this.props.comments)
        );
    }

    protected setLikers() {
        if (this.props.interactions.likers) {
            return;
        }

        this.props.dispatch(
            setLikers(this.props.uberId, this.props.likers)
        );
    }
}

const ConnectedSocialBlock = connect<IStateProps, {}, IOwnProps, Spintr.AppState>(
    (state, props) => ({
        allInteractions: state.interactions,
        focusCommentFieldForUberId: state.ui.focusCommentFieldForUberId,
        interactions: state.interactions[props.uberId] || {},
        
        hideCommentForm:
            state.instance.get("restrictedSocialFeed") &&
            state.profile.active.rights.canPostToFeed === false
}))(SocialBlock);

const ConnectedSocialBlockWithRouter = withRouter(ConnectedSocialBlock);

export default ConnectedSocialBlockWithRouter;
