import moment from "moment";
import React, { Component } from "react";
import { Line } from 'react-chartjs-2';

interface IProps {
    data: any;
    config?: any;
}

class LineChartWidget extends Component<IProps> {
    getData() {
        let data = this.props.data || [];
        let labels = [];

        for (let item of data) {
            labels.push(item.name);
        }

        const result = {
            labels,
            datasets: [{
                fill: true,
                data: data.map((x) => x.value),
                borderColor: "#5542F6",
                pointBackgroundColor: "#5542F6",
                tension: 0.4,
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 125);
                    gradient.addColorStop(0, "rgba(85, 66, 246, 0.3)");
                    gradient.addColorStop(1, "rgba(85, 66, 246, 0)");
                    return gradient;
                }
            }],
        }

        return result;
    }

    render() {
        return (
            <div className="LineChartWidget">
                <Line data={this.getData.bind(this)}
                    options={{
                        layout: {
                            padding: {
                                top: 40,
                                right: 25
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: "bottom"
                            },
                            tooltip: {
                                mode: "index",
                                intersect: false,
                                backgroundColor: '#3F4692',
                                cornerRadius: 16,
                                padding: 8,
                                caretSize: 6,
                                caretPadding: 6,
                                yAlign: "bottom",
                                displayColors: false,
                                callbacks: {
                                    label: (tooltipItem) => {
                                        let title = tooltipItem.label;

                                        for (let item of this.props.data) {
                                            if (item.name == tooltipItem.label &&
                                                !!item.title) {
                                                title = item.title;
                                            }
                                        }

                                        return title + ": " + tooltipItem.formattedValue + (this.props.config?.isPercentage ? "%" : "");
                                    },
                                    title: () => "",
                                },
                            },
                        },
                        scales: {
                            y: {
                                grid: {
                                    tickLength: 0,
                                    borderDash: [5, 5],
                                    color: "#EAECF1",
                                },
                                beginAtZero: true,
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    padding: 6,
                                    font: {
                                        size: 10,
                                    }
                                }
                            },
                            x: {
                                grid: {
                                    tickLength: 0,
                                    color: "#EAECF1",
                                },
                                beginAtZero: true,
                                ticks: {
                                    padding: 6,
                                    font: {
                                        size: 10,
                                    }
                                },
                            }
                        }
                    }}
                />
            </div>
        )
    }
}

export default LineChartWidget;
