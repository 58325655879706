import React from "react";
import { Label } from "src/ui";
import { localize } from "src/l10n";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, decodeHtmlEntities, shortenString } from "src/utils";
import { SocialPostContainer } from "../SocialPostContainer";
import "./SocialPostShare.scss";
import { SpintrTypes } from "src/typings";
import AcademyCourseListItem from "src/academy/courses/AcademyCourseListItem/AcademyCourseListItem";

interface IProps {
    post: Spintr.ISocialPostBase;
}

const SocialPostSharePost = (props: IProps) => {
    if (!props.post.Share) {
        return null;
    }

    if (!!props.post.Share.pageTitle &&
        props.post.Share.pageTitle.length > 0) {
        const bodyText = !!props.post.Share.pagePreamble && props.post.Share.pagePreamble.length > 0 ?
        props.post.Share.pagePreamble :
            props.post.Share.pageBody;

        return (
            <div className="SocialPostSharePost shared-post shared-content-post">
                {
                    props.post.Share.pageImage && (
                        <div>
                            <Link to={`/goto/${props.post.Share.Id}`}>
                                <img src={props.post.Share.pageImage} alt="" />
                            </Link>
                        </div>
                    )
                }
                <Label className="shared-content-post-title" weight="medium" size="body-2">
                    <Link
                        className={"linkFGColor general-row-break"}
                        to={`/goto/${props.post.Share.Id}`}
                    >
                        {
                            props.post.Share.pageTitle
                        }
                    </Link>
                </Label>
                {
                    bodyText && bodyText.length > 0 && (
                        <Label size="body-3" className="general-row-break">
                            {
                                shortenString(decodeHtmlEntities(bodyText), 200, "...")
                            }
                        </Label>
                    )
                }
            </div>
        )
    } else if (props.post.Share.Type === SpintrTypes.UberType.AcademyCourse) {
        return (
            <AcademyCourseListItem
                hideActionMenu
                course={{
                    ...props.post.Share,
                    id: props.post.Share.Id,
                    imageUrl: props.post.Share.ImageUrl,
                    statistics: { lessonCount: props.post.Share.lessonCount },
                    userProgress: {},
                }}
            />
        );
    }

    return (
        <div className="SocialPostSharePost shared-post">
            <SocialPostContainer
                isShare={true}
                post={props.post.Share}
            />
        </div>
    )
}

const SocialPostShare = (props: IProps) => {
    if (props.post.Share.UberIsDeleted) {
        return (
            <div className="SocialPostShare">
                <Label size="body-2">
                    {localize("DetDeladeInnehalletHarTagitsBort")}
                </Label>
            </div>
        )
    }

    return (
        <div className="SocialPostShare">
            <Label size="body-2">
                <span>{props.post.UserName.split(" ")[0] + " " + localize("Delade") + " "}</span>
                <Link className={"linkFGColor"} to={`/goto/${props.post.Share.Id}`}>
                    {localize("Ubertype" + props.post.Share.Type + "_0_0")}
                </Link>
            </Label>
            <SocialPostSharePost post={props.post} />
        </div>
    )
}

export default SocialPostShare;