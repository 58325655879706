import React, { } from "react";
import { connect } from "react-redux";
import AcademyCourseMetaDataRow from "src/academy/course/AcademyCourseMetaDataRow/AcademyCourseMetaDataRow";
import { IAcademyCourse } from "src/academy/types";
import { localize } from "src/l10n";
import { Label, PageHeader, SpintrUser } from "src/ui";
import "./AcademyCoursePreview.scss";
import AcademyImage from "../AcademyImage/AcademyImage";

interface IProps {
    course: IAcademyCourse;
}

const AcademyCoursePreview = (props: IProps) => {
    return (
        <div className="AcademyCoursePreview">
            {props.course.imageUrl && (
                <AcademyImage imageUrl={props.course.imageUrl} />
            )}
            <PageHeader
                title={localize(props.course.name)}
                standardActionMenuProps={{ objectId: props.course.id, canShare: true }}
            />
            <SpintrUser
                name={props.course.author.name}
                subText={localize("Forfattare")}
                imageUrl={props.course.author.imageUrl}
                personalName
            />
            <AcademyCourseMetaDataRow
                date={props.course.createdDate}
                lessonCount={props.course.statistics?.lessonCount}
                userCount={props.course.statistics?.usersTotal}
                language={props.course.language} />
            {props.course.description && (
                <Label className="course-description" color="dark-grey">{props.course.description}</Label>
            )}
        </div>
    )
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
});

export default connect(mapStateToProps)(AcademyCoursePreview);
