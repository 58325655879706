import React, { Component } from "react";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import AcademyCourseListItem from "src/academy/courses/AcademyCourseListItem/AcademyCourseListItem";

interface IProps {
    id: number;
}

interface IState {
    data?: any;
    isLoading: boolean;
}

class TinyAcademy extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        api.get("/api/v1/academy/courses/" + this.props.id).then((response) => {
            this.setState({
                isLoading: false,
                data: response.data,
            });
        });
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        return (
            <div className="TinyQA-wrapper">
                <AcademyCourseListItem hideActionMenu course={this.state.data} />
            </div>
        );
    }
}

export default TinyAcademy;
