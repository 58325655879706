import { Separator } from "@fluentui/react";
import { AxiosRequestConfig } from "axios";
import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, RouteChildrenProps, withRouter } from "react-router";
import { Action } from "redux";
import FeedBackInPageView from "src/feedback/FeedBackInPageView";
import { fetchSharePointAttachedFilesForPage } from "src/files/file-actions";
import SocialBlock from "src/interactions/components/SocialBlock";
import { localize } from "src/l10n";
import DeviationsInPageView from "src/msystem/DeviationsInPageView";
import ImprovementsInPageView from "src/msystem/ImprovementsInPageView";
import { canUserCreatePages, canUserDeletePage, canUserEditPage } from "src/privileges/utils";
import { IVisageSidebarMenuItem, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import {
    Breadcrumbs,
    ContentImageViewerAndSelector, ContentWithInfoPanel, ContentWithSubmenu, Label, Loader, PageHeader, PageInfoPanel, setDisableWrapperBackground, setUseDocumentWidthMode, Submenu
} from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import TimeoutInformation from "src/ui/components/TimeoutInformation";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { replaceTinyContent } from "src/ui/components/Tiny/utils";
import { UberContentView, UberContent } from "src/ui/components/UberContent";
import { capitalizeFirstLetter, NoOp, scrollToTop } from "src/utils";
import { pdf, print } from "src/utils/print";
import { clearAttachedFilesAndFolders, fetchAttachedFile, fetchAttachedFolder } from "../page-actions";
import IframePageView from "./IframePageView";
import "./PageView.scss";
import { fetchMenu } from "src/sidebar/utils";
import TranslateButton from "src/ui/components/TranslateButton";

interface IPageRouteParams {
    path: string;
    pageId: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    dispatch?: (action: Action) => void;
    instance: any;
    enableExternalFiles: boolean;
    currentUser?: any;
    privileges?: any;
    isGroupAdmin?: boolean;
    hideSubmenu?: boolean;
    hideBreadcrumbs?: boolean;
    enableCanea?: boolean;
    appMode: boolean;
    isPreview?: boolean;
    isSmallViewMode?: boolean;
    isTabletLandscapeOrSmaller?: boolean;
    attachedFiles: any;
    attachedFolders: any;
}

interface IState {
    isLoading: boolean;
    page?: any;
    pageNotFound: boolean;
    displayDeleteDialog: boolean;
    attachedFiles: any[];
    isGroupView: boolean;
    breadcrumbs: any[];
    loadTimedOut: boolean;
    translatedTexts: object;
    uberContent?: UberContent;
}

class PageView extends Component<IProps, IState> {
    private pageId = !isNaN(Number(this.props.match.params.pageId)) && this.props.match.params.pageId;
    private hideSubmenu = !!this.pageId || this.props.hideSubmenu;
    private fetchTimeoutMS = 20000;
    private _isMounted: boolean = false;

    enableDeviationManagement = this.props.instance
        .get("apps")
        .find((a) => a.name === "deviationmanagement" && a.enabled);
    enableImprovementSuggestions = this.props.instance
        .get("apps")
        .find((a) => a.name === "improvementsuggestions" && a.enabled);

    private uberContentViewRef: React.LegacyRef<HTMLDivElement>
        = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageNotFound: false,
            displayDeleteDialog: false,
            attachedFiles: [],
            isGroupView: this.props.location.pathname.indexOf("/groups/") === 0,
            breadcrumbs: [],
            loadTimedOut: false,
            translatedTexts: {},
        };
    }

    removeSlashFromPath(path) {
        if (!path) {
            return path;
        }

        if (path[0] === "/") {
            return path.substring(1, path.length);
        } else {
            return path;
        }
    }

    public componentDidMount = (): void => {
        this._isMounted = true;

        if (this.pageId) {
            this.fetchPageById(this.pageId);
        } else {
            this.fetchPage(this.removeSlashFromPath(this.props.location.pathname));
        }

        if (!this.props.isPreview && this.props.location.pathname.indexOf("/groups/") !== 0) {
            this.props.dispatch(setDisableWrapperBackground(true));
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;

        if (!this.props.isPreview) {
            this.props.dispatch(setDisableWrapperBackground(false));
        }
    }

    processPage = async (response) => {
        let item = response.data;

        this.props.dispatch(clearAttachedFilesAndFolders())

        if (!!item.attachedFiles) {
            for (const fileId of item.attachedFiles) {
                this.props.dispatch(fetchAttachedFile(fileId))
            }
        }

        if (!!item.attachedFolders) {
            for (let folder of item.attachedFolders) {
                folder.isDirectory = true
                this.props.dispatch(fetchAttachedFolder(folder, undefined))
            }
        }

        if (!item.uberContent) {
            item.uberContent = {
                uberContentRows: [],
            };

            if (!!item.preamble && item.preamble.length > 0) {
                item.uberContent.uberContentRows.push({
                    id: 0,
                    index: item.uberContent.uberContentRows.length,
                    uberContentColumns: [
                        {
                            id: 0,
                            state: 0,
                            type: 0,
                            index: 0,
                            content: '<div style="font-weight: 600;">' + item.preamble + "</div>",
                        },
                    ],
                });
            }

            item.uberContent.uberContentRows.push({
                id: 0,
                index: item.uberContent.uberContentRows.length,
                uberContentColumns: [
                    {
                        id: 0,
                        state: 0,
                        type: 0,
                        index: 0,
                        content: item.content,
                    },
                ],
            });
        }

        const { inlineFiles, inlineFolders } = replaceTinyContent(
            item.content,
            item.attachedFiles,
            item.attachedFolders
        );

        let attachedFiles = item.attachedFiles?.filter((af) => !inlineFiles.includes(af.id));

        // if (item.attachedFolders) {
        //     for (const attachedFolder of item.attachedFolders) {
        //         if (!inlineFolders.includes(attachedFolder)) {
        //             attachedFiles = [...attachedFiles, ...attachedFolder.files];
        //         }
        //     }

        //     attachedFiles.sort((a, b) => a.name.localeCompare(b.name));
        // }

        if (item.attachedSharePointListSearches?.length > 0 && this.props.enableExternalFiles) {
            this.props.dispatch(fetchSharePointAttachedFilesForPage(item.id));
        }

        const breadcrumbs = await this.fetchBreadcrumbs(item.menuId);

        scrollToTop();

        var columns = item.uberContent.uberContentRows
            .flatMap((r) => r.uberContentColumns)
            .filter(
                (r) =>
                    r.type === SpintrTypes.UberContentSectionType.Text ||
                    r.type === SpintrTypes.UberContentSectionType.Preamble
            );

        const translatedTexts = {
            [SpintrTypes.TranslateType.Title]: item.title,
            ...Object.fromEntries(columns.map((column) => [column.id, {id: column.id, text: column.content}])),
        };

        this.setState({
            isLoading: false,
            page: item,
            attachedFiles,
            breadcrumbs,
            translatedTexts,
            uberContent: item.uberContent,
        }, () => {
            this.updateSideBar();
        });
    };

    errorPage = (error): void => {
        console.log(error);

        if (error.code === "ECONNABORTED") {
            this.setState({ loadTimedOut: true, isLoading: false })
        }
        else {
            this.setState({
                pageNotFound: true,
            });
        }
    };

    public fetchPageById(pageId: string): void {
        this.setState({ isLoading: true, loadTimedOut: false }, () => {
            api.get("/api/v1/pages/" + pageId, { timeout: this.fetchTimeoutMS })
                .then(this.processPage, this.errorPage);
        })
    }

    public fetchPage(path: string): void {
        if (path.startsWith("organisation/v")) {
            path = path.replace("organisation/v/", "avdelningar/").replace("/texter/", "/").replace(/%2F/g, "/");
        }

        const config: AxiosRequestConfig = {
            params: {
                route: path,
            },
            timeout: this.fetchTimeoutMS
        };

        this.setState({ isLoading: true, loadTimedOut: false }, () => {
            api.get("/api/v1/routes", config)
                .then(this.processPage, this.errorPage);
        })
    }

    private fetchBreadcrumbs = async (id: number) => {
        let breadcrumbs = [];
        try {
            const response = await api.get("/api/v1/menu/breadcrumbs", { params: { id } });

            breadcrumbs = response.data.map((menuItem) => ({
                link: "/" + menuItem.url,
                text: menuItem.title,
            }));
        } catch { NoOp() }

        return breadcrumbs;
    };


    protected translate = (text, key: string) => {
        const uberContent = {
            ...this.state.uberContent,
            uberContentRows: this.state.uberContent.uberContentRows.map((ucr) => ({
                ...ucr,
                uberContentColumns: ucr.uberContentColumns.map((ucc) => {
                    if (ucc.id.toString() == key) {
                        ucc.content = text.text || text;
                    }

                    return ucc;
                }),
            })),
        };

        this.setState((prevState) => ({
            translatedTexts: {
                ...prevState.translatedTexts,
                [key]: text,
            },
            uberContent,
        }));
    }

    updateSideBar() {
        const isGroupPage = this.props.location.pathname.indexOf("/groups/") === 0;

        if (isGroupPage) {
            return;
        }

        const newParams = {
            id: this.state.page.menuId,
            searchQuery: "",
            groupMode: !!isGroupPage
        };

        api.get("/api/menu/submenu/tree", {
            params: newParams
        }).then((response: any) => {
            if (!this._isMounted) {
                return;
            }

            let result = [...response.data.filter(i => i.deleted !== true)];

            const setIsExpanded = (items) => {
                for (let item of items) {
                    item.isExpanded = !item.collapsed;

                    if (item.nodes) {
                        setIsExpanded(item.nodes);
                    }
                }
            };

            setIsExpanded(result);

            const getItems = (items: any[], isFirstLevel?: boolean) => {
                let visageSidebarMenuItems: IVisageSidebarMenuItem[] = [];

                for (let item of items) {
                    visageSidebarMenuItems.push({
                        key: item.id,
                        name: item.title,
                        moduleKey: item.moduleKey,
                        isExpanded: item.isExpanded,
                        children: !!item.nodes && item.nodes.length > 0 ?
                            getItems(item.nodes) :
                            undefined,
                        route: item.url,
                        routes: [
                            ...(item.moduleKey.indexOf("_") > -1 ? ["/pages/" + item.moduleKey.split("_")[1] + "/edit"] : []),
                            item.url],
                        icon: isFirstLevel ?
                            "document" :
                            undefined,
                        activeIcon: isFirstLevel ?
                            "document" :
                            undefined,
                    });
                }

                return visageSidebarMenuItems;
            }

            let convertedItems = getItems(result, true);

            if (canUserCreatePages(this.props.privileges, this.props.currentUser, this.props.instance, this.props.isGroupAdmin)) {
                const createItem = {
                    key: "create-text-page",
                    name: localize("SkapaTextsida"),
                    route: "/pages/create",
                    routes: ["/pages/create"],
                    icon: "add",
                    search: this.state.page.menuCategory && this.state.page.menuCategory.indexOf("group_") >= 0 ?
                        ("?groupId=" + this.state.page.menuCategory.substring(6) + "&parent=" + this.state.page.menuId) :
                        ("?parent=" + this.state.page.menuId)
                };

                if (convertedItems.length > 0) {
                    convertedItems[0] = {
                        ...convertedItems[0],
                        children: [
                            ...(!!convertedItems[0].children ? convertedItems[0].children : []),
                            createItem
                        ]
                    }
                } else {
                    convertedItems.push(createItem);
                }
            }

            this.props.dispatch(setSidebarItems(convertedItems, convertedItems[0].key));

            if (!isGroupPage) {
                this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu))
            }

            this.props.dispatch(expandToActiveItem());
        }).catch(() => { });
    }

    public componentDidUpdate(prevProps: IProps): void {
        const pageIdHasChanged = this.props.match.params.pageId !== prevProps.match.params.pageId;

        if (pageIdHasChanged) {
            this.setState(
                {
                    isLoading: true,
                },
                (): void => this.fetchPageById(this.props.match.params.pageId)
            );

            scrollToTop();

            return;
        }

        const pathHasChanged = this.props.match.params.path !== prevProps.match.params.path;
        const locationHasChanged = this.props.location.pathname !== prevProps.location.pathname;

        if (!pathHasChanged && !locationHasChanged) {
            return;
        }

        let newPath = this.removeSlashFromPath(this.props.location.pathname);

        this.setState(
            {
                isLoading: true,
            },
            (): void => this.fetchPage(newPath)
        );
    }

    renderDeleteDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteDialog}
                title={localize("RaderaInnehall")}
                message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())}
                onConfirm={() => {
                    this.setState(
                        {
                            isLoading: true,
                        },
                        async () => {
                            await api.put("/api/pages/" + this.state.page.id + "/toggledelete");

                            this.setState(
                                {
                                    displayDeleteDialog: false,
                                    isLoading: false,
                                    page: {
                                        ...this.state.page,
                                        isDeleted: true,
                                    },
                                },
                                () => {
                                    fetchMenu(this.props.dispatch, this.props.currentUser);
                                    this.props.history.goBack();
                                }
                            );
                        }
                    );
                }}
                onDismiss={() => {
                    this.setState({
                        displayDeleteDialog: false,
                    });
                }}
            />
        );
    }

    onDeleteClick() {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                await api.put("/api/pages/" + this.state.page.id + "/toggledelete");

                this.setState(
                    {
                        isLoading: false,
                        page: {
                            ...this.state.page,
                            isDeleted: false,
                        },
                    },
                    () => {
                        fetchMenu(this.props.dispatch, this.props.currentUser);
                    }
                );
            }
        );
    }

    renderInfoPanel(isWide?: boolean) {
        let folderFiles = []
        for (const f of this.props.attachedFolders) {
            if (!!f.data) {
                folderFiles = folderFiles.concat(f.data).filter(fo => !fo.isDirectory)
            }
        }

        return (
            <PageInfoPanel
                uberId={this.state.page.id}
                publishers={this.state.page.publishers}
                displayTags={true}
                displayLastEdited={true}
                displayReach={true}
                displayPublishers={this.state.page.showPublisher}
                isWide={!!isWide}
                displayTargets={true}
                targets={this.state.page.targets}
                tags={this.state.page.tags}
                displayFiles={this.state.page.attachedFolders.length > 0 || this.props.attachedFiles.length > 0}
                externalFiles={this.state.page.attachedExternalFiles}
                displayExternalFiles={
                    this.state.page.attachedExternalFiles?.length > 0 &&
                    this.props.enableExternalFiles
                }
                files={[...folderFiles, ...this.props.attachedFiles]}
                displayTableOfContent={true}
                tableOfContentSearchElementRef={this.uberContentViewRef}
                factcheckers={this.state.page.factcheckers}
                approvers={this.state.page.approvers}
                caneaFiles={this.state.page.caneaFiles}
                displayCaneaFiles={this.state.page.caneaFiles?.length > 0 && this.props.enableCanea}
                links={this.state.page.links}
                displayLinks={this.state.page.links.length > 0}
            />
        );
    }

    private fetchSubmenu = (params: submenuQueryParams) => {
        const isGroupPage = this.props.location.pathname.indexOf("/groups/") === 0;

        var newParams = {
            id: this.state.page.menuId,
            searchQuery: params.searchText,
            groupMode: !!isGroupPage
        };

        let submenuFetchUrl = "/api/menu/submenu/tree";

        return new Promise((resolve, reject) => {
            api.get(submenuFetchUrl, { params: newParams }).then((response: any) => {
                let result = [...response.data.filter(i => i.deleted !== true)];

                const setIsExpanded = (items) => {
                    for (let item of items) {
                        item.isExpanded = !item.collapsed;

                        if (item.nodes) {
                            setIsExpanded(item.nodes);
                        }
                    }
                };

                setIsExpanded(result);

                if (canUserCreatePages(this.props.privileges, this.props.currentUser, this.props.instance, this.props.isGroupAdmin)) {
                    result.push({
                        title: localize("SkapaTextsida"),
                        create: true,
                        id: -1,
                        action: () => {
                            let pathname = "/pages/create";

                            if (this.state.page.menuCategory && this.state.page.menuCategory.indexOf("group_") >= 0) {
                                const groupId = this.state.page.menuCategory.substring(6);

                                return this.props.history.push({
                                    pathname,
                                    search: "?groupId=" + groupId + "&parent=" + this.state.page.menuId,
                                });
                            }

                            this.props.history.push({
                                pathname,
                                search: "?parent=" + this.state.page.menuId,
                            });
                        },
                    });
                }

                resolve(result);
            });
        });
    };

    render() {
        const { enableDeviationManagement, enableImprovementSuggestions } = this;

        if (this.state.pageNotFound) {
            return <Redirect to="/404" />;
        }

        if (this.state.isLoading) {
            return <div className="PageView"><Loader /></div>;
        }

        if (this.state.loadTimedOut) {
            return <TimeoutInformation reloadFn={this.componentDidMount} />;
        }

        let isIframe = false;
        if (this.state.page.template === 5) {
            isIframe = true;
        }
        if (isIframe) {
            return <IframePageView id={this.state.page.id} />;
        }

        var columns = this.state.page.uberContent.uberContentRows
            .flatMap((r) => r.uberContentColumns)
            .filter(
                (r) =>
                    r.type === SpintrTypes.UberContentSectionType.Text ||
                    r.type === SpintrTypes.UberContentSectionType.Preamble ||
                    r.type === SpintrTypes.UberContentSectionType.UserList
            );

        return (
            <ContentWithInfoPanel
                visagePageStyle
                template={this.state.page.template}
                renderInfoPanel={this.renderInfoPanel.bind(this)}
                customSmallViewModeTrigger={this.state.isGroupView && SpintrTypes.ViewMode.TabletLandscape}
            >
                <ContentWithSubmenu hideSubmenu={!this.state.isGroupView} customSmallViewModeTrigger={this.state.isGroupView && SpintrTypes.ViewMode.SmallestLaptop} renderSubmenu={() => {
                    return (
                        <Fragment>
                            <Submenu
                                fetch={this.fetchSubmenu}
                                search={{ enabled: true }}
                                take={-1}
                                nameProp={"title"}
                            />
                        </Fragment>
                    );
                }}>
                    <div className="PageView page-margin-bottom contentMiddle">
                        <Helmet>
                            <title>{this.state.page.title}</title>
                        </Helmet>
                        {(!this.state.isGroupView && !this.props.hideBreadcrumbs && !this.props.appMode) && <Breadcrumbs displayInstance items={this.state.breadcrumbs} />}
                        <div>
                            <div>
                                {
                                    (this.state.page.expires && this.state.page.expires.indexOf(':') >= 0) && (
                                        <div className="PageView_expired">
                                            <Label size="body-2">
                                                {
                                                    capitalizeFirstLetter(localize("textsidan_small")) +
                                                    " " +
                                                    localize("HarLoptUt").toLowerCase() +
                                                    ". " +
                                                    localize("Ansvarig_Utgivare") +
                                                    " " +
                                                    localize("harBlivitNotifierade").toLowerCase()
                                                }
                                            </Label>
                                        </div>
                                    )
                                }
                                {
                                    !this.state.page.isPublished && (
                                        <div className="PageView_notPublished">
                                            <Label size="body-2">
                                                {
                                                    localize("TEXT_PAGE_NOT_PUBLISHED_BANNER")
                                                }
                                            </Label>
                                        </div>
                                    )
                                }
                                {!!this.state.page.headerImageUrl ? (
                                    <div>
                                        <ContentImageViewerAndSelector
                                            imageUrl={this.state.page.headerImageUrl}
                                            editMode={false}
                                            cropAspect={SpintrTypes.ImageCropAspect.UltraWide}
                                            autoHeight
                                            blur
                                        />
                                    </div>
                                ) : null}
                                <TranslateButton
                                    uberId={this.state.page.id}
                                    texts={{
                                        [SpintrTypes.TranslateType.Title]: this.state.page.title,
                                        ...Object.fromEntries(columns.map((column) => [column.id, {id: column.id, text: column.content, type: column.type}])),
                                    }}
                                    onTranslateFn={this.translate}
                                    infoText={localize("TEXT_PAGE_TRANSLATE_INFO")}
                                />
                                <PageHeader
                                    title={this.state.translatedTexts[SpintrTypes.TranslateType.Title]}
                                    size="big"
                                    standardActionMenuProps={{
                                        canAddToFavourites: true,
                                        canFollow: true,
                                        canShare: true,
                                        canDelete: canUserDeletePage(
                                            this.props.privileges,
                                            this.props.currentUser,
                                            this.props.instance,
                                            this.state.page.id,
                                            this.props.isGroupAdmin,
                                        ),
                                        canEdit: canUserEditPage(
                                            this.props.privileges,
                                            this.props.currentUser,
                                            this.props.instance,
                                            this.state.page.id,
                                            this.props.isGroupAdmin,
                                        ),
                                        canReport: !this.state.page.publishers.some((p) => p.id === this.props.currentUser.id),
                                        objectId: this.state.page.id,
                                        onEditClick: () => {
                                            this.props.history.push({
                                                pathname: "/pages/" + this.state.page.id + "/edit",
                                            });
                                        },
                                        onDeleteClick: () => {
                                            this.setState({
                                                displayDeleteDialog: true,
                                            });
                                        },
                                        isFollowing: this.state.page.isFollowing,
                                        isFavourite: this.state.page.isFavourite,
                                        onPrintClick: () => {
                                            print(this.props.instance.get("name"), this.state.page.title, this.state.page.currentVersion);
                                        },
                                        onPdfClick: () => {
                                            pdf(this.state.page.title, this.state.page.currentVersion);
                                        }
                                    }}
                                    lang={this.state.page?.language}
                                />
                                <div className="print-container" ref={this.uberContentViewRef}>
                                    {this.state.page.template !== 4 ? (
                                        <UberContentView
                                            uberContent={this.state.uberContent}
                                            attachedFiles={this.state.page.attachedFiles}
                                            attachedFolders={this.state.page.attachedFolders}
                                            attachedSharePointListSearches={
                                                this.state.page.attachedSharePointListSearches
                                            }
                                            language={this.state.page.language}

                                        />
                                    ) : (
                                        <TinyFormattedContent
                                            content={
                                                this.state.page.content +
                                                "<h2>" +
                                                localize("TextPageMsystemH1") +
                                                "</h2>" +
                                                this.state.page.content +
                                                "<br /><h2>" +
                                                localize("TextPageMsystemH2") +
                                                "</h2>" +
                                                this.state.page.content2 +
                                                "<br /><h2>" +
                                                localize("TextPageMsystemH3") +
                                                "</h2>" +
                                                this.state.page.content3 +
                                                "<br /><h2>" +
                                                localize("TextPageMsystemH4") +
                                                "</h2>" +
                                                this.state.page.content4 + '<br />' +
                                                this.state.page.content5 +
                                                "<br />"
                                            }
                                        />
                                    )}
                                </div>
                                {this.state.page.allowFeedback && (
                                    <div>
                                        <FeedBackInPageView pageId={this.state.page.id} />
                                    </div>
                                )}
                                {enableDeviationManagement && this.state.page.modules.msystem.allowDeviations && (
                                    <div>
                                        <Separator className="content-separator" />
                                        <DeviationsInPageView pageId={this.state.page.id} />
                                    </div>
                                )}
                                {enableImprovementSuggestions && this.state.page.modules.improvements.allowImprovements && (
                                    <div>
                                        <Separator className="content-separator" />
                                        <ImprovementsInPageView pageId={this.state.page.id} />
                                    </div>
                                )}
                                {(this.state.page.template === 2 || this.props.isSmallViewMode || (this.state.isGroupView && this.props.isTabletLandscapeOrSmaller)) && this.state.page.template !== 3 && (
                                    <div>
                                        <Separator className="content-separator" />
                                        {this.renderInfoPanel(true)}
                                    </div>
                                )}
                                {this.state.page.allowComments && (
                                    <div>
                                        <Separator className="content-separator" />
                                        <SocialBlock uberId={this.state.page.id}
                                            onCommentIconClick={() => {
                                                setTimeout(() => {
                                                    document.querySelector('.CommentsContainer')?.scrollIntoView();

                                                }, 50);
                                            }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {this.renderDeleteDialog()}
                    </div>
                </ContentWithSubmenu>
            </ContentWithInfoPanel>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,

    instance: state.instance,
    enableExternalFiles: state.profile.active.office365Connected && state.instance.get("office365Enabled"),
    currentUser: state.profile.active,
    privileges: state.privileges.data,
    enableCanea: state.instance.get("caneaUrl") !== "",
    appMode: state.ui.appMode,
    isSmallViewMode: state.ui.isSmallViewMode,
    isTabletLandscapeOrSmaller: state.ui.viewMode <= SpintrTypes.ViewMode.TabletLandscape,
    attachedFiles: state.pages.attachedFiles,
    attachedFolders: state.pages.attachedFolders
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(PageView));
