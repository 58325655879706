import { AxiosResponse } from "axios";
import moment from "moment";
import { CommandBar, ContextualMenuItemType, ICommandBarItemProps, IContextualMenuItem, SearchBox } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import {
    Breadcrumbs,
    setShouldReloadFavourites
} from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import SpintrLoader from "src/ui/components/Loader";
import { getHexFromSpintrColor } from "src/ui/helpers/style";
import { debounce } from "src/utils";
import CenteredPageHeader from "./CenteredPageHeader";
import TwoColumnListBox from "./TwoColumnListBox";
import "./WikiArticleListView.scss";
import api from "src/spintr/SpintrApi";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";

interface IProps {
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    history: any;
    restrictWikis?: boolean;
    dispatch: any;
    instance?: any;
}

interface IState {
    isLoading: boolean;
    wiki?: Spintr.IWiki2;
    hasExtendedRights: boolean;
    includeDeleted: boolean;
    fontColor: string;
    isLoadingArticles: boolean;
    articles: any[];
    searchText?: string;
    sortingModeId: string;
}

interface ISortingMode {
    id: string;
    name: string;
}

class WikiArticleListView extends Component<IProps, IState> {
    private allowChanges = !this.props.restrictWikis || this.props.isAdmin || this.props.isEditor;
    private sortingModes: ISortingMode[];

    constructor(props) {
        super(props);

        const fontColor = getHexFromSpintrColor("dark-grey");

        this.sortingModes = [
            { id: "Name", name: localize("A-Z") },
            { id: "LastChange", name: localize("SenastAndrad") },
        ];

        this.state = {
            sortingModeId: this.sortingModes[0].id,
            isLoading: true,
            wiki: null,
            hasExtendedRights: false,
            includeDeleted: false,
            fontColor,
            isLoadingArticles: true,
            articles: []
        };
    }

    componentDidMount() {
        this.fetchWiki();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.match.params.wiki !== this.props.match.params.wiki) {
            this.fetchWiki();
        }

        this.allowChanges = !this.props.restrictWikis || this.props.isAdmin || this.props.isEditor;
    }

    private onConfirmDelete = () => {
        if (this.state.wiki.deleted) {
            api.put(`/api/v1/wikis/${this.state.wiki.id}/toggledelete`).then((response: AxiosResponse) => {
                this.setState((prevState) => ({
                    wiki: {
                        ...prevState.wiki,
                        deleted: false,
                    },
                }));
            });
        } else {
            api.put(`/api/v1/wikis/${this.state.wiki.id}/toggledelete`)
                .then((response: AxiosResponse) => {
                    this.setState((prevState) => ({
                        wiki: {
                            ...prevState.wiki,
                            deleted: true,
                            // TODO: Loading?
                        },
                    }));

                    this.props.history.push({ pathname: "/wikis" });
                });
        }
    };

    fetchWiki(): void {
        this.setState({
            isLoading: true,
        }, () => {
            api.get("/api/routes", {
                params: {
                    route: `wikis/${this.props.match.params.wiki}`
                }
            }).then((response: AxiosResponse) => {
                const wiki = response.data.wiki as Spintr.IWiki2;

                const hasExtendedRights = this.props.isAdmin ||
                    this.props.isEditor ||
                    this.props.currentUserId == wiki.editor.id;

                this.setState({
                    wiki,
                    hasExtendedRights,
                    isLoading: false,
                }, () => {
                    this.getArticles();
                });
            });
        });
    }

    getArticles() {
        this.setState({
            isLoadingArticles: true,
            articles: []
        }, () => {
            api.get(`/api/wikis/pages/${this.state.wiki.id}`, {
                params: {
                    orderByColumn: this.state.sortingModeId,
                    includeDeleted: this.state.includeDeleted,
                    isAscending: this.state.sortingModeId === "Name" ? true : false,
                    searchText: this.state.searchText,
                    take: 1000,
                    skip: 0,
                },
            })
                .then((pagesResponse: AxiosResponse) => {
                    this.setState({
                        isLoadingArticles: false,
                        articles: pagesResponse.data.data.articles.map(a => {
                            return {
                                ...a,
                                description: localize("SenastAndrad") + " " + moment(a.lastchange).fromNow(),
                                renderActionMenu: () => {
                                    const canEdit = (this.state.wiki.allowEditing || this.state.hasExtendedRights || a.editor.id == this.props.currentUserId) && this.allowChanges;
                                    const canDelete = (this.state.hasExtendedRights || a.editor.id == this.props.currentUserId) && this.allowChanges;

                                    return (
                                        <StandardActionMenu
                                            canAddToFavourites={true}
                                            canFollow={true}
                                            canShare={false}
                                            canDelete={canDelete}
                                            canEdit={canEdit}
                                            canHide={false}
                                            isDeleted={a.deleted}
                                            objectId={a.id}
                                            onEditClick={() => {
                                                this.props.history.push({
                                                    pathname: `/wikis/edit-article/${a.id}`,
                                                });
                                            }}
                                            onDeleteClick={() => {
                                                this.props.dispatch(setConfirmPopup({
                                                    isOpen: true,
                                                    message:
                                                        (a.deleted ?
                                                            localize("VillDuVerkligenAterstallaX") :
                                                            localize("VillDuVerkligenTaBortX")
                                                        ).replace("{{X}}", a.name),
                                                    onConfirm: () => {
                                                        this.setState({
                                                            isLoadingArticles: true
                                                        }, () => {
                                                            api.put(`/api/v1/wikis/article/toggledelete/${a.id}`).then(() => {
                                                                this.getArticles();
                                                            });
                                                        });
                                                    },
                                                }));
                                            }}
                                            isFollowing={a.isFollowed}
                                            isFavourite={a.isFavourited}
                                        />
                                    )
                                }
                            }
                        })
                    })
                });
        });
    }

    debouncedFetchSearch = debounce(() => this.getArticles(), 500);

    searchEvent = (event: React.ChangeEvent, searchText: string): void => {
        this.setState({
            searchText
        }, () => {
            this.debouncedFetchSearch();
        });
    };

    renderCommandBar() {
        let items: ICommandBarItemProps[] = [];

        items.push({
            key: "search",
            onRender: (a, b) => {
                return (
                    <SpintrSearch
                        value={this.state.searchText}
                        onChange={this.searchEvent.bind(this)}
                        placeholder={localize("Sok") + " " + localize("Ubertype16_1_0") + "..."} />
                )
            },
        });

        let moreItems = [
            {
                key: "favorites",
                text: localize(
                    this.state.wiki.isFavourite
                        ? "TaBortFranFavoriter"
                        : "LaggTillIFavoriter"
                ),
                onClick: this.state.wiki.isFavourite
                    ? () => {
                        api
                            .delete(`/api/v1/favorites/${this.state.wiki.id}`)
                            .then((response: AxiosResponse) => {
                                this.setState((prevState) => ({
                                    wiki: {
                                        ...prevState.wiki,
                                        isFavourite: false,
                                    },
                                }));
                                this.props.dispatch(setShouldReloadFavourites(true));
                            });
                    }
                    : () => {
                        api
                            .post(`/api/v1/favorites`, { id: this.state.wiki.id })
                            .then((response: AxiosResponse) => {
                                this.setState((prevState) => ({
                                    wiki: {
                                        ...prevState.wiki,
                                        isFavourite: true,
                                    },
                                }));
                                this.props.dispatch(setShouldReloadFavourites(true));
                            });
                    },
                iconProps: {
                    iconName: this.state.wiki.isFavourite
                        ? "FavoriteStarFill"
                        : "FavoriteStar",
                },
            },
            {
                key: "notifications",
                text: localize(
                    this.state.wiki.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"
                ),
                onClick: this.state.wiki.isFollowing
                    ? () => {
                        api
                            .delete(`/api/v1/follow/${this.state.wiki.id}`)
                            .then((response: AxiosResponse) => {
                                this.setState((prevState) => ({
                                    wiki: {
                                        ...prevState.wiki,
                                        isFollowing: false,
                                    },
                                }));
                            });
                    }
                    : () => {
                        api
                            .post(`/api/v1/follow`, { id: this.state.wiki.id })
                            .then((response: AxiosResponse) => {
                                this.setState((prevState) => ({
                                    wiki: {
                                        ...prevState.wiki,
                                        isFollowing: true,
                                    },
                                }));
                            });
                    },
                iconProps: { iconName: "Flag" },
            },
            ...(((this.state.hasExtendedRights || this.state.wiki.allowNewArticles)  && this.allowChanges)
                ? [
                    {
                        key: "section1",
                        itemType: ContextualMenuItemType.Section,
                        sectionProps: {
                            topDivider: true,
                            bottomDivider: true,
                            title: localize("Redigera"),
                            items: [
                                ...(this.state.hasExtendedRights
                                    ? [
                                        {
                                            key: "includeDeleted",
                                            text: localize(
                                                this.state.includeDeleted
                                                    ? "GomBorttagnaOchOpublicerade"
                                                    : "VisaBorttagnaOchOpublicerade"
                                            ),
                                            onClick: () => {
                                                this.setState(
                                                    (prevState) => ({
                                                        includeDeleted: !prevState.includeDeleted,
                                                    }),
                                                    () => {
                                                        this.getArticles();
                                                    }
                                                );
                                            },
                                        },
                                    ]
                                    : []),
                                ...(this.state.hasExtendedRights && this.allowChanges
                                    ? [
                                        {
                                            key: "editWiki",
                                            text: localize("RedigeraWiki"),
                                            onClick: () => {
                                                this.props.history.push({
                                                    pathname: `/wikis/edit-wiki/${this.state.wiki.id}`,
                                                });
                                            },
                                        },
                                    ]
                                    : []),
                                ...(this.state.hasExtendedRights && this.allowChanges
                                    ? [
                                        {
                                            key: "deleteWiki",
                                            text: localize(
                                                this.state.wiki.deleted
                                                    ? "Aterstall"
                                                    : "TaBort"
                                            ),
                                            onClick: () => {
                                                this.props.dispatch(
                                                    setConfirmPopup({
                                                        isOpen: true,
                                                        title: localize("RaderaInnehall"),
                                                        message:
                                                            localize(
                                                                this.state.wiki.deleted
                                                                    ? "ArDuSakerAttDuVillAterstallaDennaWiki"
                                                                    : "ArDuSakerPaAttDuVillTaBortDennaWiki"
                                                            ) + "?",
                                                        onConfirm: this.onConfirmDelete,
                                                    })
                                                );
                                            },
                                        },
                                    ]
                                    : []),
                            ],
                        },
                    },
                ]
                : []),
        ];

        items.push({
            key: "sort",
            text: "Sortering",
            subMenuProps: {
                className: "media-sort",
                items: this.sortingModes.map((sortingMode) => {
                    return {
                        key: sortingMode.name,
                        text: sortingMode.name,
                        onClick: () => {
                            this.setState({
                                sortingModeId: sortingMode.id
                            }, () => {
                                this.getArticles();
                            });
                        },
                        style: {
                            fontWeight: sortingMode.id == this.state.sortingModeId ? 700 : "initial",
                        }, // supposedly deprecated in favor of styles prop, but it doesn't exist??
                    } as IContextualMenuItem;
                }),
            },
        });

        items.push({
            key: "options",
            text: localize("Alternativ"),
            subMenuProps: {
                className: "displayOptions",
                items: moreItems
            },
        });

        if (this.state.hasExtendedRights && this.state.wiki.allowNewArticles && this.allowChanges) {
            items.push({
                key: "add",
                text: localize("SkapaNyArtikel"),
                onClick: () => {
                    this.props.history.push({
                        pathname: `/wikis/create-article/${this.state.wiki.id}`,
                    });
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
            });
        }

        return (
            <CommandBar
                className="CommandBar-GrowSearch"
                items={items}
            />
        )
    }

    renderTwoColumnListBox() {
        return (
            <div className="TwoColumnListBoxWrapper">
                <TwoColumnListBox
                    isLoading={this.state.isLoadingArticles}
                    items={this.state.articles}
                    renderCommandBar={this.renderCommandBar.bind(this)} />
            </div>
        )
    }

    render() {
        const state = this.state;

        if (state.isLoading) {
            return <SpintrLoader />;
        }

        return (
            <div>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            key: "",
                            text: localize("Wikis"),
                            link: "/wikis",
                        },
                        ...(this.state.wiki
                            ? [
                                {
                                    key: this.state.wiki.id,
                                    text: this.state.wiki.name,
                                    link: this.state.wiki.url,
                                },
                            ]
                            : []),
                    ]}
                />
                <div className="WikiArticleListView page-margin-bottom">
                    <CenteredPageHeader
                        title={this.state.wiki.name}
                        description={this.state.wiki.description}
                        imageUrl={this.state.wiki.imageUrl}
                        icon={this.state.wiki.icon}
                        displayIcon={true}
                    />
                    {
                        this.renderTwoColumnListBox()
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        restrictWikis: state.instance.get("restrictWikis"),
        instance: state.instance
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(WikiArticleListView));
