import { Icon, Stack, StackItem } from "@fluentui/react";
import React, { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Drawer, DrawerBody, DrawerHeader, DrawerHeaderTitle, LargeHeading, SidebarContentListBox, WikiArticleList, SmallBody, TagList } from "src/components";
import { localize } from "src/l10n";
import { WikiPageViewRouteParams, WikiPageViewState, WikiPageViewStateProps } from "./WikiPageView.types";
import { useHistory, useParams } from "react-router";
import Axios from "axios";
import { fetchRecentlyVisitedWikiArticlesAsync, findWikiByRouteAsync } from "src/api";
import SpintrLoader from "src/ui/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ActionMenu, Breadcrumbs, toggleGreyBackground, UnstyledButton } from "src/ui";
import { scrollToTop } from "src/utils";
import { SpintrTypes } from "src/typings";
import { fetchFavorites } from "src/favorites";
import { useRealtime } from "src/hooks";
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";

const initialState: WikiPageViewState = {
    drawerOpen: false,
    loading: true,
    myRecent: [],
    myRecentLoading: true,
    favoritedWikis: [],
    favoritedWikisLoading: true,
    teamRecent: [],
    teamRecentLoading: true,
    wiki: undefined,
};

function WikiPageView(): ReactElement {
    const [state, setState] = useState<WikiPageViewState>(initialState);
    const history = useHistory();

    const onDrawerDismissed = useCallback(() => setState((prevState) => ({
        ...prevState,
        drawerOpen: false,
    })), [setState]);

    const onOpenDrawerClicked = useCallback(() => setState((prevState) => ({
        ...prevState,
        drawerOpen: true,
    })), [setState]);

    const { wiki: wikiRoute } = useParams<WikiPageViewRouteParams>();
    const {
        hasElevatedPermissions,
        isSmallViewMode,
        restrictWikis,
        currentUserId,
    } = useSelector<Spintr.AppState, WikiPageViewStateProps>(
        (appState) => ({
            hasElevatedPermissions:
                appState.profile.active.isEditor ||
                appState.profile.active.isAdmin,
            isSmallViewMode: appState.ui.isSmallViewMode,
            restrictWikis: (appState.instance.get("restrictWikis") as boolean),
            currentUserId: appState.profile.active.id,
        })
    );
    const dispatch = useDispatch();
    const { subscribe, unsubscribe } = useRealtime();

    const fetchWikiFavorites = () => {
        fetchFavorites(undefined, [SpintrTypes.UberType.Wiki, SpintrTypes.UberType.WikiArticle])
            .then((wikis) => setState((prevState) => ({
                ...prevState,
                favoritedWikis: wikis.data.map((wiki) => ({
                    key: `favorited-${wiki.id}`,
                    iconName: "volume-low-1",
                    text: wiki.title,
                    url: wiki.url,
                    wikiId: wiki.id
                })),
                favoritedWikisLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    favoritedWikisLoading: false,
                }));
            });
    }

    useEffect(() => {
        dispatch(toggleGreyBackground(true));

        return () => {
            scrollToTop();
            dispatch(toggleGreyBackground(false));
        };
    }, [dispatch, setState]);
    
    useEffect(() => {
        setState((prevState) => ({ ...prevState, loading: true }));

        const cancelTokenSource = Axios.CancelToken.source();
        
        findWikiByRouteAsync(wikiRoute, cancelTokenSource.token)
            .then((wiki) => setState((prevState) => ({
                ...prevState,
                wiki,
                loading: false,
            })))
            .catch((_) => setState((prevState) => ({
                ...prevState,
                loading: false,
            })));

        return () => cancelTokenSource.cancel();
    }, [wikiRoute]);

    const wikiId = state.wiki?.id;

    useEffect(() => {
        if (!wikiId) {
            return;
        }

        const cancelTokenSource = Axios.CancelToken.source();

        fetchRecentlyVisitedWikiArticlesAsync("me", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                myRecent: articles.map((article) => ({
                    key: `me-${article.id}`,
                    iconName: "clock",
                    text: article.name,
                    url: article.url,
                })),
                myRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    myRecentLoading: false,
                }));
            });

        fetchWikiFavorites();

        fetchRecentlyVisitedWikiArticlesAsync("team", cancelTokenSource.token)
            .then((articles) => setState((prevState) => ({
                ...prevState,
                teamRecent: articles.map((article) => ({
                    key: `team-${article.id}`,
                    iconName: "clock",
                    text: article.name,
                    url: article.url,
                })),
                teamRecentLoading: false,
            })))
            .catch((err) => {
                console.error(err);
                setState((prevState) => ({
                    ...prevState,
                    teamRecentLoading: false,
                }));
            });

        return () => cancelTokenSource.cancel();
    }, [wikiId])

    const onFavoriteToggled = useCallback((msg) => {
        if (msg.objectType !== SpintrTypes.UberType.Wiki &&
            msg.objectType !== SpintrTypes.UberType.WikiArticle) {
            return;
        }

        fetchWikiFavorites();
    }, [setState]);

    useEffect(() => {
        subscribe("Object:Favorite", onFavoriteToggled);

        return () => unsubscribe("Object:Favorite", onFavoriteToggled);
    }, [subscribe, unsubscribe]);

    const breadcrumbItems = useMemo<Spintr.IBreadcrumbItem[]>(
        () => !state.wiki ? [] : [{
            key: "wiki-root",
            text: localize("Wikis"),
            link: "/wikis",
        }, {
            key: "wiki",
            text: state.wiki.name,
            link: state.wiki.url,
        }],
        [state.wiki],
    );

    const actionMenuItems = useMemo(() => {
        if (!state.wiki) {
            return [];
        }

        const categories: Spintr.IActionMenuCategory[] = [];

        const canEdit = hasElevatedPermissions || state.wiki.editor?.id === currentUserId;

        const editorActions: Spintr.IActionMenuItem[] = [];

        if (canEdit) {
            editorActions.push({
                onClick: () => history.push({
                    pathname: `/wikis/edit-wiki/${state.wiki.id}`,
                }),
                text: localize("Redigera"),
            });

            categories.push({
                title: localize("Redaktor"),
                items: editorActions
            });
        }

        return categories;
    }, [state.wiki, hasElevatedPermissions, history, restrictWikis]);

    if (state.loading) {
        return <SpintrLoader />;
    }

    if (!state.wiki) {
        return null;
    }
    const sidebarContent = (
        <Fragment>
            <SidebarContentListBox
                className="sidebar-box pins-box"
                items={state.favoritedWikis}
                title={localize("GROUP_PINS")} />
            <SidebarContentListBox
                className="sidebar-box"
                items={state.myRecent}
                title={localize("RECENTLY_VISITED")} />
            <SidebarContentListBox
                className="sidebar-box"
                items={state.teamRecent}
                title={localize("POPULAR_ARTICLES_TEAM")} />
        </Fragment>
    );

    const sidebar = isSmallViewMode
        ? (
            <Drawer open={state.drawerOpen} position="end">
                <DrawerHeader>
                    <DrawerHeaderTitle action={
                        <UnstyledButton
                            className="close-button"
                            onClick={onDrawerDismissed}
                            title={localize("Stang")}
                        >
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    }>
                        {localize("Genvagar")}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className="WikiPageView-sidebar">
                        {sidebarContent}
                    </div>
                </DrawerBody>
            </Drawer>
        )
        : (
            <StackItem className="WikiPageView-sidebar">
                {sidebarContent}
            </StackItem>
        );

    const openDrawerButton = !isSmallViewMode ? null : (
        <UnstyledButton
            className="open-drawer-button"
            onClick={onOpenDrawerClicked}
            title={localize("Genvagar")}
        >
            <Icon iconName="GlobalNavButton" />
        </UnstyledButton>
    );

    return (
        <div id="WikiPageView">
            <Helmet>
                <title>{state.wiki.name}</title>
            </Helmet>
            <Stack className="WikiPageView-stack" horizontal={true}>
                <StackItem className="WikiPageView-main">
                    <StackItem className="WikiPageView-breadcrumbs">
                        <Breadcrumbs
                            items={breadcrumbItems}
                        />
                        <div className="WikiPageView-header-actions">
                            {actionMenuItems.length !== 0 &&
                                <ActionMenu categories={actionMenuItems} />
                            }
                        </div>
                    </StackItem>
                    <header className="WikiPageView-header">
                        <div>
                            <LargeHeading color="contentDark">{state.wiki.name}</LargeHeading>
                            <div className="WikiPageView-description">
                                <ShowMoreText
                                    lines={2}
                                    more={localize("VisaMer")}
                                    less={localize("VisaMindre")}
                                    expanded={false}
                                >
                                    {state.wiki.description}
                                </ShowMoreText>
                            </div>
                            <TagList tags={state.wiki.tags} initialDisplayAll />
                        </div>
                        {openDrawerButton}
                    </header>
                    <WikiArticleList
                        canCreateArticles={(!restrictWikis && state.wiki?.allowNewArticles) || hasElevatedPermissions}
                        wikiId={state.wiki.id}
                    />
                </StackItem>
                {sidebar}
            </Stack>
        </div>
    );
}

export default WikiPageView;
