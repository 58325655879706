import React, { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import TinyForm from "src/ui/components/Tiny/displayment/TinyForm";
import TinyQA from "src/ui/components/Tiny/displayment/TinyQA";
import stripHtml from "src/utils/stripHtml";
import TinyFile from "./displayment/TinyFile";
import TinyFolder from "./displayment/TinyFolder";
import TinySharePointListSearch from "./displayment/TinySharePointListSearch";
import TinyAcademy from "./displayment/TinyAcademy";

export const replaceTinyContent = (text: string, attachedFiles?: any, attachedFolders?: any, attachedSharePointListSearches?: any) => {
    text = (text || "").replace(/(\n|\r|\t)/gm, '');

    const splitPattern = /(<span class="redactorInlineFolder form-(?:.+?)">(?:.+?)<\/span>)|(<span class="redactorQa qa-(?:.+?)">(?:.+?)<\/span>)|(<span class="redactorAcademy academy-(?:.+?)">(?:.+?)<\/span>)|((?:\<(?:span|font|p)[^\<]*?\>\[@Folder:(?:[0-9]+)@\]\<\/(?:span|font|p)\>(?:.+?)\<(?:span|font|p)[^\<]*?\>\[\/@Folder@\]\<\/(?:span|font|p)\>))|(<div><div><span class="redactorSingleFile">(?:.+?)<\/span><\/div>(?:.+?)<div><span class="redactorSingleFile">(?:.+?)<\/span><\/div><\/div>)|(<div><span class="redactorInlineFolder sharePoint">(?:.+?)<\/span><\/div>(?:.+?)<div><span class="redactorInlineFolder">(?:.+?)<\/span><\/div>)/gim;
    const formMatchPattern = /<span class="redactorInlineFolder form-(.+?)">(.+?)<\/span>/igm;
    const qaMatchPattern = /<span class="redactorQa qa-(.+?)">(.+?)<\/span>/igm;
    const academyMatchPattern = /<span class="redactorAcademy academy-(.+?)">(.+?)<\/span>/igm;
    const inlineFolderPattern = /(?:\<(?:span|font|p)[^\<]*?\>\[@Folder:([0-9]+)@\]\<\/(?:span|font|p)\>(.+?)\<(?:span|font|p)[^\<]*?\>\[\/@Folder@\]\<\/(?:span|font|p)\>)/gim;
    const inlineFilePattern = /<div><div><span class="redactorSingleFile">(.+?)<\/span><\/div>(.+?)<div><span class="redactorSingleFile">(.+?)<\/span><\/div><\/div>/igm;
    const inlineSharePointListSearchPattern = /(?:\<(?:span|font|p)[^\<]*?\>\[@SharePointListSearch:([0-9]+)@\]\<\/(?:span|font|p)\>(.+?)\<(?:span|font|p)[^\<]*?\>\[\/@SharePointListSearch@\]\<\/(?:span|font|p)\>)/igm;
    const inlineExternalFolderPattern = /(?:\<(?:span|font|p)[^\<]*?\>\[@ExternalFolder:([0-9A-Z\|\-]+):([0-9]+)@\]\<\/(?:span|font|p)\>(.+?)\<(?:span|font|p)[^\<]*?\>\[\/@ExternalFolder@\]\<\/(?:span|font|p)\>)/gim;
    const inlineExternalFilePattern = /<div><div><span class="redactorSingleFile external">(.+?)<\/span><\/div>(.+?)<div><span class="redactorSingleFile external">(.+?)<\/span><\/div><\/div>/igm;

    const inlineFolders = [];
    const inlineFiles = [];

    const parts: ReactNode[] = text.split(splitPattern).map((part, i) => {
        const formMatch = formMatchPattern.exec(part);
        const qaMatch = qaMatchPattern.exec(part);
        const academyMatch = academyMatchPattern.exec(part);
        const inlineFolderMatch = inlineFolderPattern.exec(part);
        const inlineFileMatch = inlineFilePattern.exec(part);
        const inlineSharePointListSearchMatch = inlineSharePointListSearchPattern.exec(part);
        const inlineExternalFolderMatch = inlineExternalFolderPattern.exec(part);
        const inlineExternalFileMatch = inlineExternalFilePattern.exec(part);

        if (formMatch) {
            const formId = parseInt(formMatch[1]);

            return (
                <TinyForm key={i} id={formId} />
            );
        }

        if (qaMatch) {
            const qaId = parseInt(qaMatch[1]);

            return (
                <TinyQA key={i} id={qaId} />
            );
        }

        if (academyMatch) {
            const academyId = parseInt(academyMatch[1]);

            return (
                <TinyAcademy key={i} id={academyId} />
            );
        }

        if (attachedFolders &&
            inlineFolderMatch) {
            const folderId = parseInt(inlineFolderMatch[1], 10);
            let folder = attachedFolders.find(f => f.id === folderId);

            if (folder) {
                if (!inlineFolders.includes(folderId)) {
                    inlineFolders.push(folderId);
                }

                let folderName = inlineFolderMatch[2];

                if (folderName.indexOf("<") > -1) {
                    folderName = stripHtml(folderName);
                }

                // folder = {
                //     ...folder,
                //     name: folderName
                // };

                return (
                    <TinyFolder key={i} item={{ id: folderId, name: folderName }} />
                );
            } else {
                return null; //to avoid code from being rendered
            }
        }

        if (inlineExternalFolderMatch) {
            const externalId = inlineExternalFolderMatch[1];
            const source = inlineExternalFolderMatch[2];
            let name = inlineExternalFolderMatch[3];

            if (name.indexOf("<") > -1) {
                name = stripHtml(name);
            }

            const item = {
                externalId,
                source,
                name,
                external: true,
            };

            return <TinyFolder key={i} item={item} />;
        }

        if (inlineExternalFileMatch) {
            const externalId = inlineExternalFileMatch[1].split(":")[1];
            const source = inlineExternalFileMatch[1].split(":")[2].split("@")[0];

            const item = {
                externalId,
                source,
            };

            if (externalId && source) {
                return <TinyFile key={i} item={item} single external />;
            }
        }

        if (attachedFiles &&
            inlineFileMatch) {
            const fileId = parseInt(inlineFileMatch[1].split(':')[1].split('@')[0]);
            const file = attachedFiles.find(f => f === fileId);

            if (file) {
                if (!inlineFiles.includes(fileId)) {
                    inlineFiles.push(fileId);
                }

                return (
                    <TinyFile key={i} item={{ id: file }} single />
                );
            } else {
                return null; //to avoid code from being rendered
            }
        }

        if (attachedSharePointListSearches &&
            inlineSharePointListSearchMatch) {
            const sharePointListSearchId = parseInt(inlineSharePointListSearchMatch[1], 10);
            let sharePointListSearch = attachedSharePointListSearches.find((spls) => spls.id === sharePointListSearchId);

            if (sharePointListSearch) {
                let searchName = inlineSharePointListSearchMatch[2];

                if (searchName.indexOf("<") > -1) {
                    searchName = stripHtml(searchName);
                }

                sharePointListSearch = {
                    ...sharePointListSearch,
                    name: searchName
                };

                return <TinySharePointListSearch key={i} search={sharePointListSearch} />;
            }
        }

        return (
            <span
                dangerouslySetInnerHTML={{ __html: part }}
                key={i}
            />
        );
    });

    return {
        content: <Fragment children={parts} />,
        inlineFolders,
        inlineFiles,
    };
};