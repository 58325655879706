import React, { Fragment, FunctionComponent } from "react";
import { localize } from "src/l10n";
import { getUserPart } from "../utils";
import { likeTypes } from 'src/interactions/types';
import "./ChatMessageReactionNotification.scss";
import { Link } from "react-router-dom";

interface IProps {
    notification: Spintr.INotification;
}

const ChatMessageReactionNotification: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IChatMessageReactionNotification;

    if (!notification) {
        return;
    }

    if (notification.others && notification.others.length > 0) {
        return <Fragment>{getUserPart(notification) + " " + localize("HAS_REACTED_TO_YOUR_MESSAGE")}</Fragment>;
    }

    const parts = localize("HAS_REACTED_WITH_0_TO_YOUR_MESSAGE").split("{0}");
    const likeType = likeTypes.find((lt: any) => lt.id === notification.likeType) || likeTypes[0];

    return (
        <span className="ChatMessageReactionNotification">
            <Link to={"/goto/" + notification.userId}>{notification.userName}</Link>
            <Fragment>{" " + parts[0]}</Fragment>
            <Fragment><img src={likeType.icon} /></Fragment>
            <Fragment>{parts[1]}</Fragment>
        </span>
    );
};

export default ChatMessageReactionNotification;
