import {
    ChoiceGroup, Dropdown,
    Link, MarqueeSelection, Modal, PrimaryButton, ScrollablePane, Selection, SelectionMode, Stack,
    StackItem, TextField
} from "@fluentui/react";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { AxiosResponse } from "axios";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { fileTypeToName } from "src/files/utils";
import { localize } from "src/l10n";
import { DropZone } from "src/spintr/components";
import { IApplicationState } from "src/spintr/reducer";
import ThemeContext from "src/style/ThemeContext";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, Label, SpintrUser, Submenu, UnstyledButton } from "src/ui/components";
import { FormControl, FormSection } from "src/ui/components/Forms";
import PopupHeader from "src/ui/components/PopupHeader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { debounce, uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import api from "../SpintrApi";
import "./FilePicker.scss";

interface IState {
    items: any[];
    selected?: any;
    breadcrumbs: any[];
    isLoadingList: boolean;
    isLoading: boolean;
    activeSourceId: number;
    sources: any[];
    sharePointSites: any[];
    sharePointLibraries: any[];
    sharePointFields: any[];
    activeSharePointSite: string;
    activeSharePointLibrary: string;
    activeSharePointFields: any[];
    sharePointSiteQuery: string;
    filesUploadProgress: any[];
    isUploading?: boolean;
    displayUploadErrorDialog?: boolean;
    caneaHasSearched?: boolean;
    caneaFiles: any[];
}

interface IProps {
    onClose: () => void;
    onSelect: (data: any, source: number) => void;
    allowMultipleFiles?: boolean;
    sourcesToDisplay: Spintr.FolderRootSource[];
    initialFolderId?: string;
    showFiles?: boolean;
    selectFolders?: boolean;
    source: number;
    moveMode?: boolean;
    getUploadPromise?: (file: any, ticket: string, config: any) => void;
    isSmallViewMode: boolean;
    onLocalFilesSelected?: (files: any[]) => void;
}

class FilePicker extends React.Component<IProps, IState> {
    public static contextType = ThemeContext;
    private selection: Selection;
    private sharePointListRef = React.createRef<SpintrList>();

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            items: [],
            sources: [],
            breadcrumbs: [],
            isLoadingList: true,
            selected: [],
            activeSourceId: props.source,
            sharePointSites: [],
            sharePointLibraries: [],
            sharePointFields: [],
            activeSharePointSite: "",
            activeSharePointLibrary: "",
            activeSharePointFields: [
                {
                    name: "",
                    displayName: "",
                    value: "",
                },
            ],
            sharePointSiteQuery: "",
            filesUploadProgress: [],
            caneaFiles: [],
        };

        this.selection = new Selection({
            onSelectionChanged: this.setSelected,
            canSelectItem: this.canSelectItem,
        });

        this.fetchSources();
    }

    setSelected = () => {
        this.setState({
            selected: this.selection.getSelection(),
        });
    };

    canSelectItem = (item) => {
        return this.props.selectFolders || !item.isDirectory;
    };

    fetchSources = () => {
        api.get("/api/folders/rootfolders").then((response: AxiosResponse) => {
            var sources = response.data;
            var spintrRoot = sources.find((r) => r.source == 1);

            if (spintrRoot) {
                spintrRoot.name = localize("Filer");
            }

            sources = sources.filter((s) => this.props.sourcesToDisplay.some((std) => std == s.source));

            if (this.props.sourcesToDisplay.some((std) => std == SpintrTypes.FolderRootSource.SharePointSearch)) {
                sources.push({
                    id: -1,
                    name: localize("SharePointSokning"),
                    source: SpintrTypes.FolderRootSource.SharePointSearch,
                });
            }

            if (this.props.sourcesToDisplay.some((std) => std == SpintrTypes.FolderRootSource.Upload)) {
                sources.push({
                    id: 0,
                    name: localize("LaddaUpp"),
                    source: SpintrTypes.FolderRootSource.Upload,
                });
            }

            if (this.props.sourcesToDisplay.some((std) => std == SpintrTypes.FolderRootSource.Canea)) {
                sources.push({
                    id: -2,
                    name: "Canea",
                    source: SpintrTypes.FolderRootSource.Canea,
                });
            }

            const initialFolderId =
                this.props.initialFolderId || sources.find((s) => s.source === this.props.source)?.id;

            if (initialFolderId !== 0) {
                // SpintrTypes.FolderRootSource.Upload, TODO: Make prettier?
                this.openFolder(initialFolderId);
            }
        });
    };

    openFolder = (id: string) => {
        this.setState({
            isLoadingList: true,
        });

        var params = {
            source: this.state.activeSourceId, // ?
        };

        api.get(`/api/folders/${id}`, { params: params }).then((response: AxiosResponse) => {
            var arr = [
                {
                    id: response.data.id,
                    text: response.data.name,
                    onClick: () => {
                        this.openFolder(response.data.id);
                    },
                },
            ];
            var getParent = (parentFolder) => {
                if (parentFolder == null) {
                    return;
                }
                arr.push({
                    id: parentFolder.id,
                    text: parentFolder.name,
                    onClick: () => {
                        this.openFolder(parentFolder.id);
                    },
                });

                getParent(parentFolder.parent);
            };

            getParent(response.data.parent);

            api
                .get("/api/files", {
                    params: {
                        folderId: response.data.id,
                        take: 999,
                        orderByColumn: "name",
                        source: this.state.activeSourceId,
                        isAscending: true,
                    },
                })
                .then((response: AxiosResponse) => {
                    var items = response.data.data;

                    if (!this.props.showFiles) {
                        items = items.filter((i) => i.isDirectory);
                    }

                    items = items.map((item) => ({
                        ...item,
                        date: moment(item.date).format("L"),
                        typeName: item.isDirectory ? localize("Mapp") : localize(fileTypeToName[item.type], true),
                    }));

                    this.setState({
                        items,
                        breadcrumbs: arr.reverse(),
                        isLoadingList: false,
                    });
                });
        });
    };

    openSharePointSearch = () => {
        this.setState({
            activeSourceId: SpintrTypes.FolderRootSource.SharePointSearch,
            breadcrumbs: [
                {
                    id: -1,
                    text: localize("SharePointSokning"),
                    onClick: () => { },
                },
            ],
        });
    };

    openCaneaSearch = () => {
        this.setState({
            activeSourceId: SpintrTypes.FolderRootSource.Canea,
            breadcrumbs: [
                {
                    id: -2,
                    text: "Canea",
                    onClick: () => { },
                },
            ],
        });
    };

    closeDialog = () => {
        this.props.onClose();
    };

    save = () => {
        if (this.state.activeSourceId == SpintrTypes.FolderRootSource.SharePointSearch) {
            var fieldNames = [];
            var fieldValues = [];

            for (let field of this.state.activeSharePointFields) {
                if (!field.name || !field.value) {
                    continue;
                }
                fieldNames.push(field.name);
                fieldValues.push(field.value);
            }

            api
                .post("/api/files/office365/attached", {
                    siteId: this.state.activeSharePointSite,
                    libraryId: this.state.activeSharePointLibrary,
                    fieldNames: fieldNames,
                    fieldValues: fieldValues,
                })
                .then((response: AxiosResponse) => {
                    this.props.onSelect(response.data, this.state.activeSourceId);
                    this.closeDialog();
                });
            return;
        }

        this.props.onSelect(this.state.selected, this.state.activeSourceId);
        this.closeDialog();
    };

    debouncedSearchSiteChanged = debounce(() => {
        api
            .get("/api/files/office365/sites", {
                params: {
                    query: this.state.sharePointSiteQuery,
                },
            })
            .then((response: AxiosResponse) => {
                this.setState({
                    sharePointSites: response.data,
                });
            });
    }, 500);

    debouncedReFetch = debounce(() => {
        if (this.sharePointListRef.current) {
            this.sharePointListRef.current.reFetch();
        }
    }, 500);

    renderSharePointSearch = () => {
        return (
            <div style={{ width: 300, paddingLeft: 15, paddingTop: 15 }}>
                <FormSection>
                    <FormControl>
                        <TextField
                            placeholder={localize("SokSite")}
                            onChange={(event, value) => {
                                this.setState({ sharePointSiteQuery: value }, this.debouncedSearchSiteChanged);
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <ChoiceGroup
                            options={this.state.sharePointSites.map((site) => ({
                                key: site.id,
                                text: site.displayName,
                            }))}
                            onChange={(ev, option) => {
                                api
                                    .get("/api/files/office365/libraries", {
                                        params: {
                                            siteId: option.key,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        this.setState({
                                            sharePointLibraries: response.data,
                                            activeSharePointSite: option.key,
                                        });
                                    });
                            }}
                        />
                    </FormControl>
                </FormSection>

                <FormSection>
                    <FormControl>
                        <Dropdown
                            placeholder={localize("ValjBibliotek")}
                            disabled={this.state.sharePointLibraries.length === 0}
                            options={this.state.sharePointLibraries.map((library) => ({
                                key: library.id,
                                text: library.displayName,
                            }))}
                            onChange={(event, option) => {
                                api
                                    .get("/api/files/office365/fields", {
                                        params: {
                                            siteId: this.state.activeSharePointSite,
                                            libraryId: option.key,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        this.setState({
                                            sharePointFields: response.data,
                                            activeSharePointLibrary: option.key.toString(),
                                        });
                                    });
                            }}
                        />
                    </FormControl>
                </FormSection>

                {this.state.activeSharePointFields.map((field, idx) => (
                    <FormSection key={`field${idx}`}>
                        <FormControl>
                            <Dropdown
                                placeholder={localize("ValjFalt")}
                                disabled={this.state.sharePointFields.length === 0}
                                options={this.state.sharePointFields.map((field) => ({
                                    key: field.id,
                                    text: field.displayName,
                                }))}
                                onChange={(event, option, fieldIdx) => {
                                    var activeFields = [...this.state.activeSharePointFields];
                                    var activeField = { ...activeFields[idx] };
                                    var field = this.state.sharePointFields[fieldIdx];
                                    activeField.name = field.name;
                                    activeField.displayName = field.displayName;

                                    activeFields[idx] = activeField;

                                    this.setState((prevState) => ({
                                        activeSharePointFields: activeFields,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                placeholder={localize("Faltvarde")}
                                onChange={(event, value) => {
                                    var activeFields = [...this.state.activeSharePointFields];
                                    var activeField = { ...activeFields[idx] };
                                    activeField.value = value;

                                    activeFields[idx] = activeField;

                                    if (idx === this.state.activeSharePointFields.length - 1) {
                                        // Last item in array, add a new one
                                        activeFields.push({
                                            name: "",
                                            displayName: "",
                                            value: "",
                                        });
                                    }

                                    this.setState(
                                        {
                                            activeSharePointFields: activeFields,
                                        },
                                        this.debouncedReFetch
                                    );
                                }}
                                disabled={!field.name}
                            />
                        </FormControl>
                    </FormSection>
                ))}

                {this.state.activeSharePointFields.length > 0 && this.state.activeSharePointFields[0].value && (
                    <SpintrList
                        ref={this.sharePointListRef}
                        fetch={() => {
                            return new Promise((resolve, reject) => {
                                var fieldNames = [];
                                var fieldValues = [];

                                for (let field of this.state.activeSharePointFields) {
                                    if (!field.name || !field.value) {
                                        continue;
                                    }
                                    fieldNames.push(field.name);
                                    fieldValues.push(field.value);
                                }

                                api
                                    .get("/api/files/office365/previewsearch", {
                                        params: {
                                            siteId: this.state.activeSharePointSite,
                                            libraryId: this.state.activeSharePointLibrary,
                                            fieldNames: fieldNames,
                                            fieldValues: fieldValues,
                                        },
                                    })
                                    .then((response: AxiosResponse) => {
                                        resolve({
                                            data: response.data,
                                            totalCount: response.data.length,
                                        });
                                    });
                            });
                        }}
                        columns={[
                            {
                                name: localize("Name"),
                                fieldName: "name",
                            },
                        ]}
                        orderByColumn="name"
                        disablePagination
                        disableSort
                        disableCommandBar
                    />
                )}
            </div>
        );
    };

    renderCaneaSearch = () => {
        return (
            <div className={classNames({ hasNotSearched: !this.state.caneaHasSearched })}>
                <SpintrList
                    ref={this.sharePointListRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            if (searchQuery.length == 0) {
                                resolve({
                                    data: [],
                                    totalCount: 0,
                                });
                                return;
                            }

                            if (!this.state.caneaHasSearched) {
                                this.setState({
                                    caneaHasSearched: true,
                                });
                            }

                            api
                                .get("/api/v1/files/caneasearch", {
                                    params: {
                                        query: searchQuery,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    resolve({
                                        data: response.data,
                                        totalCount: response.data.length,
                                    });
                                });
                        });
                    }}
                    columns={[
                        {
                            name: localize("Name"),
                            fieldName: "name",
                        },
                    ]}
                    selectionMode={this.props.allowMultipleFiles ? SelectionMode.multiple : SelectionMode.single}
                    selection={this.selection}
                    orderByColumn="name"
                    disablePagination
                    disableSort
                    take={999}
                />
            </div>
        );
    };

    renderProgressBar() {
        let sum = 0;

        for (let file of this.state.filesUploadProgress) {
            sum += file.progress;
        }

        const avg = sum / this.state.filesUploadProgress.length;

        return (
            <div className="file-picker-progress-bar-wrapper">
                <ProgressIndicator label={localize("LaddarUpp")} percentComplete={avg} />
            </div>
        );
    }

    renderUploadView() {
        if (this.state.isUploading) {
            return this.renderProgressBar();
        }

        return (
            <div>
                <DropZone
                    isMultiple={this.props.allowMultipleFiles}
                    fileTypesString={"*"}
                    fileTypes={"*"}
                    onFilesSelected={(files) => {
                        if (this.props.onLocalFilesSelected) {
                            return this.props.onLocalFilesSelected(files);
                        }

                        this.setState(
                            {
                                isUploading: true,
                            },
                            () => {
                                let promises = [];

                                for (let file of files) {
                                    let ticket = new Date().getTime().toString() + uniqueId();

                                    const config = {
                                        onUploadProgress: (progressEvent) => {
                                            let item = this.state.filesUploadProgress.find(
                                                (fp) => fp.ticket === ticket
                                            );

                                            let itemProgress =
                                                Math.floor((progressEvent.loaded / progressEvent.total) * 10) / 10;

                                            if (!item) {
                                                this.setState({
                                                    filesUploadProgress: [
                                                        ...this.state.filesUploadProgress,
                                                        {
                                                            ticket,
                                                            progress: itemProgress,
                                                        },
                                                    ],
                                                });
                                            } else {
                                                this.setState({
                                                    filesUploadProgress: this.state.filesUploadProgress.map((fp) => {
                                                        if (fp.ticket !== ticket) {
                                                            return fp;
                                                        } else {
                                                            return {
                                                                ...fp,
                                                                progress: itemProgress,
                                                            };
                                                        }
                                                    }),
                                                });
                                            }
                                        },
                                    };

                                    promises.push(this.props.getUploadPromise(file, ticket, config));
                                }

                                Promise.all(promises)
                                    .then((result) => {
                                        let returnArray = [];

                                        for (let req of result) {
                                            returnArray.push(req.data.Result ? req.data.Result : req.data);
                                        }

                                        this.setState(
                                            {
                                                isUploading: false,
                                                filesUploadProgress: [],
                                            },
                                            () => {
                                                this.props.onSelect(returnArray, this.state.activeSourceId);
                                                this.closeDialog();
                                            }
                                        );
                                    })
                                    .catch(() => {
                                        this.setState({
                                            displayUploadErrorDialog: true,
                                        });
                                    });
                            }
                        );
                    }}
                />
            </div>
        );
    }

    public render() {
        let selectFileText = this.props.allowMultipleFiles
            ? localize("Valj") + " " + localize("filer_small")
            : localize("ValjFil");

        let buttonText = this.props.moveMode
            ? `${localize("Flytta")}${this.state.selected.length > 0 ? ` ${localize("Till_small")} ${this.state.selected[0].name}` : ``
            }`
            : `${selectFileText}${this.state.selected.length > 0 ? ` (${this.state.selected.length})` : ``}`;
        let allowSave =
            this.state.activeSourceId == SpintrTypes.FolderRootSource.SharePointSearch ||
            this.state.selected.length > 0;

        return (
            <Modal isOpen={true}>
                <PopupHeader
                    text={this.props.moveMode ? localize("ValjMal") : selectFileText}
                    onClose={this.closeDialog} />
                <div className="file-picker-popup">
                    {!this.props.isSmallViewMode && (
                        <Stack horizontal className="bar">
                            <div className="source">
                                <Label>{localize("Kalla")}</Label>
                            </div>
                            {this.state.activeSourceId === SpintrTypes.FolderRootSource.Upload ? null : (
                                <Stack horizontal grow={1}>
                                    <StackItem grow={1}>
                                        <Breadcrumbs items={this.state.breadcrumbs} />
                                    </StackItem>

                                    <UnstyledButton className="save-button" onClick={this.save} disabled={!allowSave}>
                                        <Label>{buttonText}</Label>
                                    </UnstyledButton>
                                </Stack>
                            )}
                        </Stack>
                    )}
                    <Stack horizontal={!this.props.isSmallViewMode}>
                        <div className="sources">
                            <Submenu
                                fetch={() => {
                                    return new Promise((resolve, reject) => {
                                        api.get("/api/folders/rootfolders").then((response: AxiosResponse) => {
                                            var sources = response.data;
                                            var spintrRoot = sources.find((r) => r.source == 1);

                                            if (spintrRoot) {
                                                spintrRoot.name = localize("Filer");
                                            }

                                            sources = sources.filter((s) =>
                                                this.props.sourcesToDisplay.some((std) => std == s.source)
                                            );

                                            sources = sources.map((source) => ({
                                                name: source.name,
                                                id: source.id,
                                                active:
                                                    this.state.activeSourceId == source.source &&
                                                    this.state.activeSourceId !=
                                                    SpintrTypes.FolderRootSource.SpintrGroup,
                                                action: () => {
                                                    this.setState(
                                                        {
                                                            activeSourceId: source.source,
                                                        },
                                                        () => this.openFolder(source.id)
                                                    );
                                                },
                                            }));

                                            if (
                                                this.props.sourcesToDisplay.some(
                                                    (std) => std == SpintrTypes.FolderRootSource.SharePointSearch
                                                )
                                            ) {
                                                sources.push({
                                                    id: -1,
                                                    name: localize("SharePointSokning"),
                                                    action: this.openSharePointSearch,
                                                });
                                            }

                                            if (
                                                this.props.sourcesToDisplay.some(
                                                    (std) => std == SpintrTypes.FolderRootSource.Canea
                                                )
                                            ) {
                                                sources.push({
                                                    id: -2,
                                                    name: "Canea",
                                                    action: this.openCaneaSearch,
                                                });
                                            }

                                            if (
                                                this.props.sourcesToDisplay.some(
                                                    (std) => std == SpintrTypes.FolderRootSource.Upload
                                                )
                                            ) {
                                                sources.push({
                                                    id: 0,
                                                    name: localize("LaddaUpp"),
                                                    action: () => {
                                                        this.setState({
                                                            activeSourceId: SpintrTypes.FolderRootSource.Upload,
                                                        });
                                                    },
                                                });
                                            }

                                            resolve(sources);
                                        });
                                    });
                                }}
                                take={9999}
                                dontHideInSmallViewMode
                            />
                        </div>
                        <div className="content">
                            <ScrollablePane>
                                {this.state.activeSourceId == SpintrTypes.FolderRootSource.SharePointSearch ? (
                                    <>{this.renderSharePointSearch()}</>
                                ) : this.state.activeSourceId == SpintrTypes.FolderRootSource.Canea ? (
                                    <>{this.renderCaneaSearch()}</>
                                ) : this.state.activeSourceId == SpintrTypes.FolderRootSource.Upload ? (
                                    <>{this.renderUploadView()}</>
                                ) : (
                                    <MarqueeSelection
                                        selection={this.selection}
                                        isEnabled={!!this.props.allowMultipleFiles}
                                    >
                                        <SpintrList
                                            fetch={() => { }}
                                            data={{ data: this.state.items, totalCount: this.state.items.length }}
                                            isLoading={this.state.isLoadingList}
                                            columns={[
                                                {
                                                    name: localize("Namn"),
                                                    fieldName: "name",
                                                    minWidth: 200,
                                                    onRender: (item) => {
                                                        return (
                                                            <>
                                                                {item.previewImage ? (
                                                                    <div
                                                                        className="preview-image"
                                                                        style={{
                                                                            backgroundImage: `url(${item.previewImage})`,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div className="icon-wrapper">
                                                                        <Visage2Icon icon={item.isDirectory ? "folder" : "document-text"} title={localize("Last")} />
                                                                    </div>
                                                                )}
                                                                {item.isDirectory ? (
                                                                    <Link
                                                                        className="fs-body-2"
                                                                        onClick={() => {
                                                                            this.openFolder(item.id);
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </Link>
                                                                ) : (
                                                                    <Label as="span" size="body-2">
                                                                        {item.name}
                                                                    </Label>
                                                                )}
                                                            </>
                                                        );
                                                    },
                                                },
                                                {
                                                    name: localize("Typ"),
                                                    fieldName: "typeName",
                                                    isCollapsable: true,
                                                },
                                                {
                                                    name: localize("Datum"),
                                                    fieldName: "date",
                                                    isCollapsable: true,
                                                },
                                                {
                                                    name: localize("UppladdadAv"),
                                                    fieldName: "ownerName",
                                                    isCollapsable: true,
                                                    onRender: (item: Spintr.IFileBrowserFile) => {
                                                        if (!item.owner) {
                                                            return;
                                                        }

                                                        return (
                                                            <SpintrUser
                                                                name={item.owner.name}
                                                                imageUrl={item.owner.image}
                                                                hideText
                                                                personalName
                                                            />
                                                        );
                                                    },
                                                },
                                            ]}
                                            selectionMode={
                                                this.props.allowMultipleFiles
                                                    ? SelectionMode.multiple
                                                    : SelectionMode.single
                                            }
                                            selection={this.selection}
                                            disablePagination
                                            disableCommandBar
                                            disableSort
                                            take={999}
                                            orderByColumn="name"
                                        />
                                    </MarqueeSelection>
                                )}
                            </ScrollablePane>
                        </div>
                    </Stack>
                    {this.state.activeSourceId === SpintrTypes.FolderRootSource.Upload ? null : (
                        <div className="footer">
                            <PrimaryButton disabled={!allowSave} text={buttonText} onClick={this.save} />
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(FilePicker);
