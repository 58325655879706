import { localizeFormat } from "src/l10n";


export interface IFormModel { [property : string] : any }

/* * * * * * * * * *
 * Error Messages  *
 * * * * * * * * * */

/**
 * Represents a function that takes in a field name and returns an error message.
 */
export type ErrorMessageHandler = (fieldName : string) => string | null;

export const isRequired : ErrorMessageHandler = (fieldName : string) : string => 
    localizeFormat("VALIDATION_FIELDNAME_IS_REQUIRED", fieldName);

export const invalidFormat : ErrorMessageHandler = 
    (fieldName : string) : string => 
        localizeFormat("VALIDATION_INVALID_FORMAT_FOR_FIELDNAME", fieldName);

export const tooShort = (length : number) : ErrorMessageHandler => 
    (fieldName : string) : string =>
        localizeFormat(
            "VALIDATION_FIELDNAME_MUST_BE_X_CHARACTERS", 
            fieldName, 
            length);

export const tooLong = (length : number) : ErrorMessageHandler => 
    (fieldName : string) : string =>
        localizeFormat(
            "VALIDATION_FIELDNAME_MAX_X_CHARACTERS", 
            fieldName, 
            length);

/* * * * *
 * Rules *
 * * * * */

/**
 * Represents a function that takes in a text (and a model) and returns an ErrorMessageHandler
 */
export type ValidationRule = 
    (text : string, model : IFormModel) => ErrorMessageHandler;

export const minLength = (length : number) : ValidationRule => 
    (text : string) : ErrorMessageHandler => 
        text.length >= length ? null : tooShort(length);

export const maxLength = (length: number) : ValidationRule => 
    (text: string) : ErrorMessageHandler => 
        text.length <= length ? null : tooLong(length);

export const required = () : ValidationRule => 
    (text : string) : ErrorMessageHandler => 
        !!text ? null : isRequired;

export const regex = (pattern : RegExp, errorFn?: ErrorMessageHandler) : ValidationRule => 
    (text : string) : ErrorMessageHandler => pattern.test(text) ? null : errorFn ?? invalidFormat;

export const oneOf = (rules: ValidationRule[]) : ValidationRule =>
    (text: string, model: IFormModel): ErrorMessageHandler => {
        let lastError: ErrorMessageHandler = null;

        for (const rule of rules) {
            const errFn = rule(text, model);

            if (!errFn) {
                return null;
            }

            lastError = errFn;
        }

        return lastError;
    };

export const patterns = (patterns : RegExp[]) : ValidationRule =>
    (text: string): ErrorMessageHandler => {
        for (const pattern of patterns) {
            pattern.lastIndex = 0; // Reset last index

            if (pattern.test(text)) {
                return null;
            }
        }

        return invalidFormat;
    };

/* * * * * * * *
 * Validation  *
 * * * * * * * */
export interface IValidationResult { [fieldName : string] : string };

export type FieldValidator = (model : IFormModel) => IValidationResult;

export const fieldValidation = 
    (fieldName : string, displayName : string, ...rules : ValidationRule[]) 
        : FieldValidator => 
            (model : IFormModel) : IValidationResult => {
                for (const rule of rules) {
                    const errFn = rule(model[fieldName], model);

                    if (errFn) {
                        return { [fieldName]: errFn(displayName) }
                    }
                }

                return null;
            };

export const validateModel = 
    (model : IFormModel, validators : FieldValidator[]) : IValidationResult =>
            validators.reduce(
                (acc, validator) => Object.assign(acc, validator(model)),
                {}
            );