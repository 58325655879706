import { Checkbox, Dropdown, IDropdownOption, IDropdownProps, Stack, StackItem } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import { Style } from "src/ui/helpers";
import SpintrDatePicker from "../SpintrDatePicker";
import { SpintrTypes } from "src/typings";
import moment from "moment";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    content: Spintr.ISocialSystemStatus;
    post: Spintr.ISocialPostBase;
    onAttachedContentUpdate?: any;
    onPostUpdate?: any;
    onRemove?: any;
    disableEditing?: boolean;
}

interface IState {}

class Visage2ComposerSystemStatus extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    getTypes() {
        return [{
            type: SpintrTypes.SystemStatusType.Ongoing,
            text: localize("Pagaende"),
            color: Style.getHexFromSpintrColor("red")
        }, {
            type: SpintrTypes.SystemStatusType.Done,
            text: localize("Avklarad"),
            color: Style.getHexFromSpintrColor("spintrGreen")
        }, {
            type: SpintrTypes.SystemStatusType.Planned,
            text: localize("Planerad"),
            color: Style.getHexFromSpintrColor("orange")
        }, {
            type: SpintrTypes.SystemStatusType.Information,
            text: localize("Information"),
            icon: "info-circle"
        }]
    }

    getSelectedType() {
        if (this.props.content.systemStatusType === SpintrTypes.SystemStatusType.Information) {
            return SpintrTypes.SystemStatusType.Information;
        }

        const now = new Date();

        if (this.props.content.endDate && this.props.content.endDate < now) {
            return SpintrTypes.SystemStatusType.Done;
        }

        if (this.props.content.startDate > now) {
            return SpintrTypes.SystemStatusType.Planned;
        }

        return SpintrTypes.SystemStatusType.Ongoing;
    }

    render(): ReactNode {
        const types = this.getTypes();

        return (
            <div className="Visage2ComposerSystemStatus">
                <FormControl>
                    <Dropdown
                        placeholder={localize("Typ")}
                        options={types.map((d) => ({
                            key: d.type,
                            text: d.text,
                        }))}
                        onRenderTitle={(options: IDropdownOption[]) => {
                            const option = options[0];
                            const type = types[parseInt(option.key.toString()) - 1];

                            return (
                                <div className="dropdown-title">
                                    {type.color && (
                                        (
                                            <div
                                                className="status-ball"
                                                style={{
                                                    backgroundColor: type.color
                                                }}
                                            />
                                        )
                                    )}
                                    {type.icon && (
                                        <Visage2Icon icon={type.icon} size="small" />
                                    )}
                                    <span>{option.text}</span>
                                </div>
                            )
                        }}
                        onRenderOption={(option: IDropdownOption) => {
                            const type = types[option.index];
                            
                            return (
                                <div className="dropdown-option">
                                    {type.color && (
                                        <div
                                            className="status-ball"
                                            style={{
                                                backgroundColor: type.color
                                            }}
                                        />
                                    )}
                                    {type.icon && (
                                        <Visage2Icon icon={type.icon} size="small" />
                                    )}
                                    <span>{option.text}</span>
                                </div>
                            )
                        }}
                        label={localize("Typ")}
                        selectedKey={this.getSelectedType()}
                        onChange={(event, option) => {
                            if (this.getSelectedType() === option.key) {
                                return;
                            }

                            switch (option.key) {
                                case SpintrTypes.SystemStatusType.Ongoing:
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        startDate: moment().toDate(),
                                        endDate: undefined,
                                        systemStatusType: undefined
                                    });
                                    break;
                                case SpintrTypes.SystemStatusType.Done:
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        startDate: moment().toDate(),
                                        endDate: moment().toDate(),
                                        systemStatusType: undefined
                                    });
                                    break;
                                case SpintrTypes.SystemStatusType.Planned:
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        startDate: moment().add(1, "day").toDate(),
                                        endDate: moment().add(2, "day").toDate(),
                                        systemStatusType: undefined
                                    });
                                    break;
                                case SpintrTypes.SystemStatusType.Information:
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        startDate: moment().toDate(),
                                        endDate: moment().toDate(),
                                        systemStatusType: SpintrTypes.SystemStatusType.Information,
                                        publishStartDate: moment().toDate(),
                                        responsibleUser: undefined,
                                        prioritized: false
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </FormControl>
                {this.props.content.systemStatusType !== SpintrTypes.SystemStatusType.Information && (
                    <div>
                        <FormControl>
                            <SpintrDatePicker
                                label={localize("Publicera")}
                                value={this.props.content.publishStartDate}
                                onChangeHandler={(date) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        publishStartDate: date,
                                    });
                                }}
                            />
                        </FormControl>
                        <Stack horizontal grow tokens={{ childrenGap: Style.getSpacing(3) }}>
                            <StackItem grow>
                                <FormControl>
                                    <SpintrDatePicker
                                        label={localize("Startar")}
                                        value={this.props.content.startDate}
                                        onChangeHandler={(date) => {
                                            this.props.onAttachedContentUpdate({
                                                ...this.props.content,
                                                startDate: date,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </StackItem>
                            <StackItem grow>
                                <FormControl>
                                    <SpintrDatePicker
                                        label={localize("Slutar")}
                                        value={this.props.content.endDate}
                                        onChangeHandler={(date) => {
                                            this.props.onAttachedContentUpdate({
                                                ...this.props.content,
                                                endDate: date,
                                            });
                                        }}
                                        isClearable
                                    />
                                </FormControl>
                            </StackItem>
                        </Stack>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={this.props.content.responsibleUser ? [this.props.content.responsibleUser] : []}
                                label={localize("RESPONSIBLE_PERSON")}
                                types={[SpintrTypes.UberType.User]}
                                itemLimit={1}
                                onChange={(p) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        responsibleUser: p != null && p.length > 0 ?
                                            {
                                                ...p[0],
                                                id: p[0].key
                                            } :
                                            undefined
                                    });
                                }}
                            />
                        </FormControl>
                        {/*}
                        <FormControl>
                            <Checkbox
                                label={localize("Fast")}
                                checked={this.props.post.isPinned}
                                onChange={(ev, checked) => {
                                    this.props.onPostUpdate({
                                        ...this.props.post,
                                        isPinned: checked,
                                    });
                                }}
                            />
                        </FormControl>
                        */}
                        <FormControl>
                            <Checkbox
                                label={localize("PRIORITIZED_SYSTEM_STATUS")}
                                checked={this.props.content.prioritized}
                                onChange={(ev, checked) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        prioritized: checked,
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Checkbox
                                label={localize("SHOW_ON_MAIN_FEED")}
                                checked={this.props.content.showOnMainFeed}
                                onChange={(ev, checked) => {
                                    this.props.onAttachedContentUpdate({
                                        ...this.props.content,
                                        showOnMainFeed: checked,
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                )}
            </div>
        );
    }
}

export default Visage2ComposerSystemStatus;
