import React, { useEffect, useState } from "react";
import { Label } from "src/ui";
import { Bar } from 'react-chartjs-2';
import "./BarChartWidget.scss";

interface IProps {
    data: any;
    config?: any;
    isZoomed?: boolean;
    variables: { [key: string]: string };
}

const BarChartWidget = (props: IProps) => {
    const [altLabel, setAltLabel] = useState<string>("");
    const [colors] = useState<string[]>(["#98c3ff", "#ffdea5", "#ffd7d9", "#4b9f47"]);

    useEffect(() => {
        if (props.data.filter(x => !!x.altText).length === 1) {
            setAltLabel(props.data.find(x => !!x.altText).altText);
        }
    }, [props.data]);

    return (
        <>
            <div className="BarChartWidget">
                <Bar
                    data={{
                        labels: (props.data || []).map((x) => x.name),
                        datasets: [{
                            data: (props.data || []).map((x) => x.value),
                            borderColor: "#5542F6",
                            backgroundColor: colors,
                            borderRadius: 7,
                            barThickness: 12
                        }],
                    }}
                    options={{
                        layout: {
                            padding: {
                                top: 40,
                                right: 25
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false
                        },
                        plugins: {
                            legend: {
                                display: false,
                                position: "bottom"
                            },
                            tooltip: {
                                mode: "index",
                                intersect: false,
                                backgroundColor: '#3F4692',
                                cornerRadius: 16,
                                padding: 8,
                                caretSize: 6,
                                caretPadding: 6,
                                displayColors: false
                            },
                        },
                        scales: {
                            y: {
                                grid: {
                                    tickLength: 0,
                                    borderDash: [5, 5],
                                    color: "#EAECF1",
                                },
                                beginAtZero: true,
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    padding: 6,
                                    font: {
                                        size: 10,
                                    }
                                }
                            },
                            x: {
                                grid: {
                                    tickLength: 0,
                                    color: "#EAECF1",
                                },
                                beginAtZero: true,
                                ticks: {
                                    padding: 6,
                                    font: {
                                        size: 10,
                                    }
                                },
                            }
                        }
                    }}
                />
            </div>
            {!!altLabel && (
                <Label className="BarChartWidget-label altText altTextMiddle">
                    {altLabel}
                </Label>
            )}
        </>
    )
}

export default BarChartWidget;