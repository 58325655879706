import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import SocialPostView from "src/social-feed/components/SocialPostView/SocialPostView";
import { IApplicationState } from "src/spintr/reducer";
import "src/groups/views/GroupSocialPostView.scss";

interface IRouteParams {
    id: string;
    statusId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    group: any;
    dispatch?: any;
}

class GroupSocialPostView extends Component<IProps> {
    render() {
        return (
            <div
                className="GroupFeedView GroupSocialPostView"
            >
                <SocialPostView
                    match={this.props.match}
                    location={this.props.location}
                    history={this.props.history}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
});

export default connect(mapStateToProps)(GroupSocialPostView);