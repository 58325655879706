
import React, { MutableRefObject, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from "react";
import "./CalypsoContentWithSidebar.scss";
import classNames from 'classnames';

type RefType = {
    toggleIsExpanded: () => boolean;
    setSidebarKey: (key: string) => void;
}

type Props = PropsWithChildren<{
    innerRef?:              MutableRefObject<RefType>;
    renderSideBar:          () => ReactElement;
    sidebarStartsExpanded?: boolean | undefined;
    noPadding?:             boolean;
}>;

function CalypsoContentWithSidebar({
    children,
    innerRef,
    renderSideBar,
    sidebarStartsExpanded,
    noPadding
}: Props): ReactElement {
    const [isExpanded, setIsExpanded] = useState<boolean>(!!sidebarStartsExpanded);
    const [sidebarKey, setSidebarKey] = useState<string>("");

    useEffect(() => {
        if (!innerRef) {
            return;
        }

        innerRef.current = {
            toggleIsExpanded() {
                setIsExpanded(!isExpanded);

                return !isExpanded;
            },
            setSidebarKey(key: string) {
                setSidebarKey(key);
            }
        };
    }, [isExpanded]);

    const className = useMemo(() => classNames(
        "CalypsoContentWithSidebar",
        { "expanded": isExpanded },
        { "no-padding": noPadding}
    ), [isExpanded]);

    return (
        <div className={className}>
            <div className="content">
                {children}
            </div>
            <div className="sidebar" key={sidebarKey}>
                {renderSideBar()}
            </div>
        </div>
    )
};

export default CalypsoContentWithSidebar;
