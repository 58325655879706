import { ActionButton, TextField, Image } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import DropZone from "../DropZone";
import api from "src/spintr/SpintrApi";
import { uniqueId } from "src/utils";
import { ReactSVG } from "react-svg";
import SocialPostTypeContentConfetti from "src/social-feed/components/SocialPostTypeContent/SocialPostTypeContentConfetti";

interface IProps {
    post: any;
    onAttachedContentUpdate: any;
    onRemove: any;
    isSomethingFun?: boolean;
    disableEditing?: boolean;
    isVideo?: boolean;
}

interface IState {}

class Visage2ComposerImages extends Component<IProps, IState> {
    protected canvasRef: React.RefObject<HTMLCanvasElement>;

    constructor(props: IProps) {
        super(props);
    }

    protected async onFilesSelected(files: File[]): Promise<void> {
        // Ensure array, not FileList
        files = [...files];

        if (files.length === 0) {
            return;
        }

        const isVideo = files[0].type.indexOf("video/") === 0;
        if (isVideo) {
            this.props.onAttachedContentUpdate({
                ...this.props.post,
                Video: files[0],
                BlobUrl: URL.createObjectURL(files[0])
            });

            return;
        }

        // Remove any non-images
        files = files.filter((file) => file.type.indexOf("image/") === 0);

        const promises = files.map((file) => {
            const body = new FormData();
            const ticket = new Date().getTime().toString() + uniqueId();

            body.append("type", "1");
            body.append("file", file, file.name);
            body.append("thumbnailSize", "natural");
            body.append("ticket", ticket);

            const config = {
                onUploadProgress: (progressEvent) => {
                    
                },
            };

            return {
                fileName: file.name,
                promise: api.post("/api/uploads", body, config),
            };
        });

        const uploads = await Promise.all(promises.map(async (file) => ({
            fileName: file.fileName,
            result: await file.promise,
        })));

        this.props.onAttachedContentUpdate({
            ...this.props.post,
            photos: [
                ...(this.props.post.photos || []),
                ...uploads.map((upload) => ({
                        imageTicket: {
                            remove: false,
                            ticket: upload.result.data.Result.ticket,
                        },
                        thumbnailUrl: upload.result.data.Result.thumbnailUrl,
                }))
            ]
        });
    }

    onDismissTypeClick(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.props.onRemove();
    }

    protected onVideoMetadataLoaded(event: React.SyntheticEvent<HTMLVideoElement>): void {
        const videoPlayer = event.target as HTMLVideoElement;

        const duration = videoPlayer.duration;

        setTimeout(() => {
            videoPlayer.currentTime = duration / 2;
        }, 200);
    }

    protected onVideoSeeked(event: React.SyntheticEvent<HTMLVideoElement>): void {
        const videoPlayer = event.target as HTMLVideoElement;

        const canvas = this.canvasRef?.current
            ? this.canvasRef.current
            : videoPlayer.parentElement.querySelector("canvas");
        if (!canvas) {
            return;
        }

        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        
        const dataUrl = context.canvas.toDataURL("image/jpeg", 0.75);

        this.props.onAttachedContentUpdate({
            ...this.props.post,
            VideoThumbnail: dataUrl
        });
    }

    render(): ReactNode {
        const images = this.props.post.photos;

        if (this.props.post.Video) {
            return (
                <div>
                    <video
                        autoPlay={false}
                        controls={false}
                        style={{
                            display: "none",
                        }}
                        onLoadedMetadata={this.onVideoMetadataLoaded.bind(this)}
                        onSeeked={this.onVideoSeeked.bind(this)}
                        src={this.props.post.BlobUrl}
                    />
                    <canvas
                        style={{
                            display: "none",
                        }}
                        ref={this.canvasRef}
                    />
                    {this.props.post.VideoThumbnail && (
                        <div className="video-stage">
                            <Image
                                alt={this.props.post.Video.name}
                                className="thumbnail"
                                src={this.props.post.VideoThumbnail}
                            />
                            {!this.props.disableEditing && (
                                <UnstyledButton
                                    className="remove-button close-button-circle"
                                    onClick={this.onDismissTypeClick.bind(this)}
                                >
                                    <Visage2Icon icon="close-circle" />
                                </UnstyledButton>
                            )}
                        </div>
                    )}
                </div>
            )
        }

        const addText = localize(this.props.isVideo ?
            "ADD_VIDEO" :
            "ADD_PHOTO");

        const fileTypes = this.props.isVideo ?
            "video/*" :
            "image/*";

        return (
            <div className="Visage2ComposerImages">
                {this.props.isSomethingFun && (
                    <div className="decoration-button-small">
                        <UnstyledButton
                            onClick={() => {
                                this.props.onAttachedContentUpdate({
                                    ...this.props.post,
                                    displayDecorations: !this.props.post.displayDecorations
                                })
                            }}
                            title={localize(this.props.post.displayDecorations ? "HIDE_DECORATIONS" : "SHOW_DECORATIONS")}>
                            <Visage2Icon icon={this.props.post.displayDecorations ? "eye-slash" : "eye"} color="white" />
                        </UnstyledButton>
                    </div>
                )}
                <div className="SocialComposerImages">
                    {this.props.isSomethingFun && this.props.post.displayDecorations && (
                        <SocialPostTypeContentConfetti displayEmojis />
                    )}
                    <div className="images">
                        <DropZone
                            isMultiple={!this.props.isSomethingFun && !this.props.isVideo}
                            fileTypesString={fileTypes}
                            onFilesSelected={this.onFilesSelected.bind(this)}
                            disabled={this.props.disableEditing && !this.props.isSomethingFun}
                        >
                            {images.length === 0 && (
                                <div className="upload-placeholder">
                                    <div className="content">
                                        <Visage2Icon icon="add-circle" />
                                        <Label
                                            color="visageGray"
                                            size="body-1"
                                            weight="medium"
                                        >
                                            {addText}
                                        </Label>
                                        <Label
                                            color="mid-grey"
                                            size="body-2"
                                        >
                                            {localize("OR_DRAG_DROP")}
                                        </Label>
                                    </div>
                                    {!this.props.disableEditing && (
                                        <UnstyledButton
                                            className="remove-button close-button-circle"
                                            onClick={this.onDismissTypeClick.bind(this)}
                                        >
                                            <Visage2Icon icon="close-circle" />
                                        </UnstyledButton>
                                    )}
                                </div>
                            )}
                            {images.length > 0 && (
                                <div className={"image-stage display" + Math.min(5, images.length)}>
                                    {images.slice(0, 5).map((img, i) => (
                                        <div key={i} className="image">
                                            <div
                                                className="image-wrapper"
                                                tabIndex={0}
                                                data-index={i}
                                            >
                                                <img alt="" src={typeof img.thumbnailUrl === "string" ? img.thumbnailUrl : ""} />
                                                {(!this.props.disableEditing || this.props.isSomethingFun) && (
                                                    <div className="remove">
                                                        <div
                                                            className="icon-wrapper close-button-circle"
                                                            data-index={i}
                                                            onClick={(e) => {
                                                                if (e) {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }

                                                                const allItems = [...this.props.post.photos];

                                                                allItems.splice(i, 1);

                                                                this.props.onAttachedContentUpdate({
                                                                    ...this.props.post,
                                                                    photos: allItems,
                                                                });
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            <Visage2Icon icon="close-circle" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </DropZone>
                    </div>
                </div>
            </div>
        );
    }
}

export default Visage2ComposerImages;