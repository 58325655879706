import { AxiosResponse } from "axios";
import {
    CommandBar,
    SearchBox, Stack,
    StackItem
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import FilePicker from "src/spintr/components/FilePicker";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { ActionMenu, addUploads, Breadcrumbs, updateUpload } from "src/ui";
import { PageHeader, RightsPopup } from "src/ui/components";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import { debounce } from "src/utils";
import {
    deleteFile, deleteFolder,
    deleteOfficeFolder, newFolder, setSearchText, updateFile, updateFolder, uploadedFile
} from "../file-actions";
import { setHideShare } from "../folder-actions";
import { checkFileExtension } from "../utils";
import CreateFilePopup from "./CreateFilePopup";
import "./FileBrowserHeader.css";
import UploadsInProgressIndicator from "./UploadsInProgressIndicator";
import api from "src/spintr/SpintrApi";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { IContentHeaderButton } from "src/ui/components/PageHeader";

interface IProps {
    id: string;
    source: Spintr.FolderRootSource;
    isFileUser: boolean;
    isEditor: boolean;
    isAdmin: boolean;
    folder?: any;
    enableZoho: boolean;
    selectedItems: Spintr.IFileBrowserFile[];
    restrictedExtensions: string;
    isSmallViewMode: boolean;
    location: Location;
    history: any;
    dispatch: (action: Action) => void;
    roots: any[];
    rootFolderId?: string;

    group?: any;
    isInTeamsApp?: boolean;
}

interface IState {
    searchText: string;
    isCreateFilePopupOpen: boolean;
    isConfirmPopupOpen: boolean;
    isRightsPopupOpen: boolean;
    infoDialogMessage?: string;
    isInfoDialogOpen?: boolean;
    isFilePickerOpen?: boolean;
    breadcrumbs: any[];
}

interface IFolder {
    enableOnlineEdit: boolean;
    id: number;
    isDirected: boolean;
    itemCount: number;
    name: string;
    parent?: IFolderParent;
    type: number;
}

interface IFolderParent {
    id: number;
    isDirected: boolean;
    name: string;
    parent?: IFolderParent;
}

class FileBrowserHeader extends Component<IProps, IState> {
    private fileInput: HTMLInputElement;

    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            isCreateFilePopupOpen: false,
            isRightsPopupOpen: false,
            breadcrumbs: [],
            isConfirmPopupOpen: false,
        };
    }

    componentDidMount() {
        this.fetchFolder();
    }

    componentWillUnmount() {
        this.props.dispatch(setSearchText(""));
    }

    componentDidUpdate(prevProps: IProps, prevState) {
        if (prevProps.id !== this.props.id && this.props.id !== "0") {
            if (this.state.searchText !== "") {
                this.setState({ searchText: "" })
                this.props.dispatch(setSearchText(""))
            }

            this.fetchFolder();
        }
    }

    fetch = () => {
        this.props.dispatch(setSearchText(this.state.searchText));
    };

    fetchFolder = () => {
        var params = {
            source: this.props.source,
            externalId: this.props.group && this.props.group.externalId,
        };

        api.get(`/api/folders/${this.props.id}`, { params: params }).then((response: AxiosResponse) => {
            let folder = response.data as IFolder;

            var isDirected = folder.isDirected;

            var arr = [];
            var getParent = (parentFolder: IFolderParent) => {
                if (parentFolder == null) {
                    return;
                }

                isDirected = isDirected || parentFolder.isDirected;
                arr.push({
                    id: parentFolder.id,
                    text: parentFolder.name,
                    link: this.props.group
                        ? `/groups/${this.props.group.id}/files/d${parentFolder.id}`
                        : `/files/${this.props.source}/d${parentFolder.id}`,
                });

                getParent(parentFolder.parent);
            };

            getParent(folder.parent);

            this.setState({ breadcrumbs: arr.reverse() });

            this.props.dispatch(setHideShare(isDirected));
        }).catch(() => { });
    };

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, value: string) => {
        this.setState(
            {
                searchText: value,
            },
            this.debouncedFetchSearch
        );
    };

    newFolder = () => {
        this.props.dispatch(newFolder());
    };

    newFile = () => {
        this.setState({
            isCreateFilePopupOpen: true,
        });
    };

    download = () => {
        var selectedItem = this.props.selectedItems[0] as Spintr.IFileBrowserFile;

        if (this.props.isInTeamsApp && selectedItem.url) {
            window.open(selectedItem.url, "_blank");
            return;
        }

        if (selectedItem.downloadUrl) {
            window.location.href = selectedItem.downloadUrl;
        } else if (
            this.props.source == SpintrTypes.FolderRootSource.Office365Public ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Private ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Group
        ) {
            api
                .get(`/api/files/office365/${selectedItem.id}`, {
                    params: {
                        source: this.props.source,
                        externalId: this.props.group && this.props.group.externalId,
                    },
                })
                .then((response: AxiosResponse) => {
                    window.open(response.data.downloadUrl, "_blank");
                });
        } else {
            window.location.href = `/api/servefile/${selectedItem.id}`;
        }
    };

    delete = () => {
        if (this.props.selectedItems.length === 1) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    title: localize("VillDuVerkligenTaBortX").replace("{{X}}", this.props.selectedItems[0].name),
                    onConfirm: this.deleteAction,
                })
            );
        } else {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    title: localize("ArDuSakerPaAttDuVillTaBortDessaObjekt") + "?",
                    onConfirm: this.deleteAction,
                })
            );
        }
    };

    deleteAction = () => {
        for (var item of this.props.selectedItems) {
            if (item.isDirectory) {
                if (this.props.source === SpintrTypes.FolderRootSource.Office365Private ||
                    this.props.source === SpintrTypes.FolderRootSource.Office365Public) {
                    this.props.dispatch(deleteOfficeFolder(item.id, this.props.source));
                } else {
                    this.props.dispatch(deleteFolder(item.id));
                }
            } else {
                this.props.dispatch(deleteFile(item.id, this.props.source));
            }
        }
    };

    closeConfirmPopup = () => {
        this.setState({
            isConfirmPopupOpen: false,
        });
    };

    uploadFile = (file: File, folderId: string) => {
        if (checkFileExtension(file, this.props.restrictedExtensions)) {
            this.setState({
                isInfoDialogOpen: true,
                infoDialogMessage: `${localize("FilTypInteTillaten")}: ${file.name}`,
            });
            return;
        }

        let body = new FormData();
        body.append("type", "3");
        body.append("folderId", folderId);
        body.append("file", file);
        // feedId if classic group?

        var url = "/api/uploads";
        const isGoogleDrive = this.props.source === SpintrTypes.FolderRootSource.GoogleDrive;

        if (
            this.props.source == SpintrTypes.FolderRootSource.Office365Public ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Private ||
            this.props.source == SpintrTypes.FolderRootSource.Office365Group
        ) {
            url = "/api/uploads/office365";
            body.append("source", this.props.source.toString());
        } else if (isGoogleDrive) {
            url = "/api/v1/files/google";
        }

        const config = {
            onUploadProgress: (progressEvent) => {
                this.props.dispatch(updateUpload({
                    uploadId: file.lastModified,
                    total: progressEvent.total,
                    loaded: progressEvent.loaded
                }));
            },
        };

        return api
            .post(url, body, config)
            .then((response: AxiosResponse) => {
                const { data } = response;

                if (this.props.id === folderId) {
                    this.props.dispatch(uploadedFile(isGoogleDrive ? data : data.Result));
                }
            });
    };

    runUploadQueue = (files: File[], folderId: string) => {
        var slice = files.length > 5 ? 5 : files.length;
        var promises = files.slice(0, slice);
        var p = [];

        for (let file of promises) {
            p.push(this.uploadFile(file, folderId));
        }

        Promise.all(p).then(() => {
            files = files.slice(slice, files.length);

            if (files.length > 0) {
                this.runUploadQueue(files, folderId);
            }
        });
    };

    onFilesSelected(filesInput: FileList) {
        let files = Array.from(filesInput);
        const folderId = this.props.id.toString();

        let uploads = [];

        for (const file of files) {
            uploads.push({
                uploadId: file.lastModified,
                total: file.size,
                loaded: 0
            });
        }

        this.props.dispatch(addUploads(uploads));

        this.runUploadQueue(files, folderId);
    }

    openFilePicker = () => {
        this.setState({
            isFilePickerOpen: true,
        });
    };

    dismissInfoDialog = () => {
        this.setState({
            isInfoDialogOpen: false,
        });
    };

    closeRightsPopup = () => {
        this.setState({
            isRightsPopupOpen: false,
        });
    };

    render() {
        if (!this.props.folder) return <div />;

        var isGroup = this.props.location.pathname.includes("group");
        var source = Number(this.props.source);
        var isValidSource =
            source == SpintrTypes.FolderRootSource.SpintrGroup ||
            source == SpintrTypes.FolderRootSource.Office365Private ||
            source == SpintrTypes.FolderRootSource.Office365Group;

        var isOffice365 =
            source == SpintrTypes.FolderRootSource.Office365Public ||
            source == SpintrTypes.FolderRootSource.Office365Private ||
            source == SpintrTypes.FolderRootSource.Office365Group;

        var canWrite = this.props.isFileUser || this.props.isEditor || this.props.isAdmin || isGroup || isValidSource;
        var displayFolderRightsItem =
            this.props.isAdmin &&
            !isGroup &&
            this.props.source != SpintrTypes.FolderRootSource.Office365Public &&
            this.props.source != SpintrTypes.FolderRootSource.Office365Private;

        return (
            <div className="FileBrowserHeader">
                <UploadsInProgressIndicator />
                <Breadcrumbs
                    displayInstance={isGroup ? false : true}
                    items={[
                        ...this.state.breadcrumbs,
                        {
                            key: this.props.folder.get("id"),
                            text: this.props.folder.get("name"),
                            link: this.props.group
                                ? `/groups/${this.props.group.id}/files/d${this.props.folder.get("id")}`
                                : `/files/${this.props.source}/d${this.props.folder.get("id")}`,
                        },
                    ]}
                />
                <div className="FileBrowserHeader-wrapper">
                    <div>
                        <PageHeader
                            title={this.props.folder.get("name")}
                            titleMenuCategories={[
                                {
                                    items: [
                                        ...this.props.roots.map((root) => ({
                                            text: root.get("name"),
                                            onClick: () => {
                                                this.props.history.push({
                                                    pathname: `/files/${root.get("source")}/d${root.get("id")}`,
                                                });
                                            },
                                        })),
                                    ],
                                },
                            ]}
                            actionMenuCategories={displayFolderRightsItem
                                ? [
                                    {
                                        items: [{
                                            key: "rights",
                                            text: localize("Rattigheter"),
                                            onClick: () => {
                                                this.setState({
                                                    isRightsPopupOpen: true,
                                                });
                                            },
                                        }]
                                    },
                                ]
                                : []}
                            displaySearch
                            buttons={[
                                ...(canWrite
                                    ? [
                                        ...(this.props.selectedItems.length < 1
                                            ? [
                                                {
                                                    key: "upload",
                                                    text: localize("LaddaUpp"),
                                                    onClick: () => {
                                                        this.fileInput.click();
                                                    },
                                                    iconProps: { iconName: "Upload" },
                                                    icon: "document-upload",
                                                },
                                                ...(this.props.enableZoho
                                                    ? [
                                                        {
                                                            key: "newFile",
                                                            text: localize("SkapaNyFil"),
                                                            onClick: this.newFile,
                                                            iconProps: { iconName: "Add" },
                                                        },
                                                    ]
                                                    : []),
                                                {
                                                    key: "newFolder",
                                                    text: localize("NyMapp"),
                                                    onClick: this.newFolder,
                                                    iconProps: { iconName: "FabricNewFolder" },
                                                    icon: "folder-add",
                                                    className: "commandBarAddButton",
                                                    theme: "primary",
                                                    /*
                                                    onRenderIcon: () => {
                                                        return (
                                                            <Visage2Icon icon={"add"} size="big" color={"white"} />
                                                        )
                                                    }
                                                    */
                                                },
                                            ]
                                            : []),
                                        ...(this.props.selectedItems.length == 1 && !this.props.selectedItems[0].isDirectory
                                            ? [
                                                {
                                                    key: "download",
                                                    text: localize("LaddaNed"),
                                                    onClick: this.download,
                                                    iconProps: { iconName: "Download" },
                                                    onRenderIcon: () => {
                                                        return (
                                                            <Visage2Icon
                                                                icon={"import-1"}
                                                                color="light-blue" />
                                                        )
                                                    }
                                                },
                                            ]
                                            : []),
                                        ...(this.props.selectedItems.length > 0
                                            ? [
                                                ...(!isOffice365
                                                    ? [
                                                        {
                                                            key: "move",
                                                            text: localize("LaggINyMapp"),
                                                            onClick: this.openFilePicker,
                                                            iconProps: { iconName: "MoveToFolder" },
                                                            onRenderIcon: () => {
                                                                return (
                                                                    <Visage2Icon
                                                                        icon={"folder-2"}
                                                                        color="light-blue" />
                                                                )
                                                            }
                                                        },
                                                    ]
                                                    : []),
                                                {
                                                    key: "delete",
                                                    text: localize("TaBort"),
                                                    onClick: this.delete,
                                                    iconProps: { iconName: "ChromeClose" },
                                                    onRenderIcon: () => {
                                                        return (
                                                            <Visage2Icon
                                                                icon={"trash"}
                                                                color="light-blue" />
                                                        )
                                                    }
                                                },
                                            ]
                                            : []),
                                    ]
                                    : []),
                            ] as IContentHeaderButton[]}
                            onSearchQueryChange={(value: string) => {
                                this.searchEvent(null, value);
                            }}
                        />
                    </div>
                </div>
                <input
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    className="file-upload"
                    onChange={(e) => {
                        this.onFilesSelected(e.target.files);
                    }}
                />
                <CreateFilePopup
                    folderId={this.props.id}
                    source={this.props.source}
                    isOpen={this.state.isCreateFilePopupOpen}
                    close={() => {
                        this.setState({ isCreateFilePopupOpen: false });
                    }}
                    group={this.props.group}
                />
                {this.state.isRightsPopupOpen && (
                    <RightsPopup
                        isOpen
                        close={this.closeRightsPopup}
                        source={this.props.source}
                        folderName={this.props.folder.get("name")}
                        folderId={this.props.folder.get("id")}
                        enableOnlineEdit={this.props.folder.get("enableOnlineEdit")}
                    />
                )}
                {
                    !!this.state.isInfoDialogOpen && (
                        <InfoDialog
                            message={this.state.infoDialogMessage}
                            show={this.state.isInfoDialogOpen}
                            onDismiss={this.dismissInfoDialog}
                        />
                    )
                }
                {this.state.isFilePickerOpen && (
                    <FilePicker
                        onClose={() => {
                            this.setState({
                                isFilePickerOpen: false,
                            });
                        }}
                        onSelect={(data, source) => {
                            for (let selectedItem of this.props.selectedItems) {
                                if (selectedItem.isDirectory) {
                                    let params = {
                                        id: selectedItem.id,
                                        name: selectedItem.name,
                                        parentId: data[0].id,
                                        parentIdStr: data[0].id,
                                        source: source,
                                    };
                                    this.props.dispatch(updateFolder(params, this.props.id));
                                } else {
                                    let params = {
                                        id: selectedItem.id,
                                        name: selectedItem.name,
                                        folderId: data[0].id,
                                        source: source,
                                    };
                                    this.props.dispatch(updateFile(params, this.props.id));
                                }
                            }
                        }}
                        initialFolderId={this.props.source == SpintrTypes.FolderRootSource.SpintrGroup && this.props.id}
                        sourcesToDisplay={[this.props.source]}
                        source={this.props.source}
                        moveMode
                        selectFolders
                    />
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,

    folder: state.files.folders
        .get("items")
        .find((i) => i.get("source") === props.source && i.get("id") === props.id),
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    isFileUser: state.profile.active.isFileUser,
    selectedItems: state.files.files.selectedItems,
    enableZoho: state.instance.get("enableZoho"),
    restrictedExtensions: state.instance.get("restrictFileUploads"),
    isSmallViewMode: state.ui.isSmallViewMode,
    roots: state.files.folders.get("roots"),
    isInTeamsApp: state.ui.isInTeamsApp,
});

export default connect(mapStateToProps)(FileBrowserHeader);
