import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, Label, Loader, PageHeader, SpintrUser } from "src/ui";
import LoadMoreButton from "src/ui/components/Buttons/LoadMoreButton/LoadMoreButton";
import { FormTokenizedObjectInput } from "src/ui/components/Forms";
import { debounce } from "src/utils";
import { getActivityText } from "./ActivityLogUtil";
import "./ActivityLogView.scss";

interface Props extends RouteComponentProps {
    currentUser: any;
}

interface State {
    isLoading: boolean;
    items: any[];
    feed: any[];
    searchText: string;
    uberType: number;
    type: number;
    user: any;
    hasMore: boolean;
}

class ActivityLogView extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const typeId = parseInt(query.get("typeId"), 10) || 0;
        const type = parseInt(query.get("type"), 10) || 0;

        this.state = {
            isLoading: true,
            items: [],
            feed: [] as any,
            searchText: "",
            uberType: typeId,
            type: type,
            user: undefined,
            hasMore: true,
        };
    }

    processActivity = (item) => {
        let { feed } = this.state;
        const language = this.props.currentUser.settings.language;
        item.Text = getActivityText(language, item);
        item.Created = new Date(item.Created);
        let d = item.Created.toDateString();
        if (d in feed) {
            feed[d].items.push(item);
        } else {
            feed[d] = {
                date: d,
                items: [item],
            };
        }
        this.setState({ feed });
    };

    fetch = () => {
        const { uberType, user, type } = this.state;

        let userId = -1;

        if (user) {
            userId = user.key;
        }

        this.setState(
            {
                isLoading: true,
                //feed: []
            },
            () => {
                api
                    .get(`/api/v1/activitylog/${userId}`, {
                        params: {
                            filter: type,
                            ubertype: uberType,
                            skip: this.state.items.length,
                            take: 40,
                            checkHasMore: true,
                        },
                    })
                    .then((response) => {
                        // Process into dategroups
                        response.data.forEach((i) => {
                            this.processActivity(i);
                        });

                        this.setState({
                            items: [...this.state.items, ...response.data],
                            isLoading: false,
                            hasMore: response.data.length === 40,
                        });
                    });
            }
        );
    };

    componentDidMount = () => {
        this.fetch();
    };

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, searchText: string): void => {
        this.setState({ searchText }, () => {
            this.debouncedFetchSearch();
        });
    };

    public componentDidUpdate = () => {
        const query = new URLSearchParams(this.props.location.search);
        const typeId = parseInt(query.get("typeId"), 10) || 0;
        const type = parseInt(query.get("type"), 10) || 0;

        if (typeId !== this.state.uberType || type !== this.state.type) {
            this.setState(
                {
                    uberType: typeId,
                    type,
                    feed: [],
                    items: [],
                },
                () => {
                    this.fetch();
                }
            );
        }
    };

    render() {
        let { feed, user } = this.state;

        const isInAdminView = window.location.href.indexOf("/admin") > -1;

        return (
            <div className="ActivityLogView">
                <Helmet>
                    <title>{localize("Aktivitetslogg")}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(isInAdminView ? [{
                            text: localize("Administration"),
                            key: "administration",
                            link: "/admin",
                        }] : []),
                        {
                            text: localize("Aktivitetslogg"),
                            key: "activitylog",
                            link: isInAdminView ? "/admin/activitylog" : "/activitylog",
                        },
                    ]}
                />
                <div>
                    <div>
                        <PageHeader
                            title={localize("Aktivitetslogg")}
                            subText={localize("PAGE_INFO_ACTIVITYLOG")}
                            useShowMoreSubText />
                        <FormTokenizedObjectInput
                            itemLimit={1}
                            types={[SpintrTypes.UberType.User]}
                            items={user ? [user] : []}
                            label={localize("SokAnvandare")}
                            onChange={(t) => {
                                this.setState(
                                    {
                                        user: t[0],
                                        feed: [],
                                        items: [],
                                    },
                                    () => {
                                        this.fetch();
                                    }
                                );
                            }}
                        />
                        {Object.keys(feed).map((key, index) => {
                            let dateGroup = feed[key];
                            return (
                                <div key={index}>
                                    <div className="dateGroup">
                                        <Label size="h4" as="h4">
                                            {new Date().toDateString() === dateGroup.date
                                                ? localize("Idag")
                                                : new Date().getDate() - 1 === dateGroup.date
                                                    ? localize("Igar")
                                                    : dateGroup.date.toLocaleString()}
                                        </Label>
                                    </div>

                                    {dateGroup.items.map((item, idx) => {
                                        return (
                                            <div key={idx} className="feedItem">
                                                <div className="feedItem-left">
                                                    <SpintrUser
                                                        name={item.Name}
                                                        imageUrl={item.ImageUrl}
                                                        hideText={true}
                                                        personalName={true}
                                                    />
                                                </div>
                                                <div className="feedItem-right">
                                                    <Link to={`/profile/${item.UserId}`}>{item.Name}</Link>{" "}
                                                    {item.Text()}
                                                    <Label
                                                        title={moment(item.Created).format("LLL")}
                                                        size="small-1"
                                                        as="div"
                                                        role="heading"
                                                        className="fw-medium"
                                                    >
                                                        {moment(item.Created).fromNow()}
                                                    </Label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        {!this.state.isLoading && this.state.hasMore && (
                            <LoadMoreButton onClick={this.fetch.bind(this)} />
                        )}
                        {this.state.isLoading && <Loader />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,

    currentUser: state.profile.active,
});

export default withRouter(connect(mapStateToProps)(ActivityLogView));
