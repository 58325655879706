import { Action } from "redux";
import { SpintrTypes } from "src/typings";
import { IReactionPickerConfig } from "./reducer";

export enum UiActionTypes {
    SetSidebarExpanded = "UI_SET_SIDEBAR_EXPANDED",
    SetIsSmallViewMode = "UI_SET_IS_SMALL_VIEW_MODE",
    SetIsTinyInModal = "UI_SET_IS_TINY_IN_MODAL",
    SetViewMode = "UI_SET_VIEW_MODE",
    SET_ACTIVE_ACTION_MENU = "SET_ACTIVE_ACTION_MENU",
    SetDisplayAsOfflineInChat = "UI_SET_DISPLAY_AS_OFFLINE_IN_CHAT",
    SetActiveSidebarTab = "UI_SET_ACTIVE_SIDEBAR_TAB",
    SetShouldReloadFavourites = "UI_SET_SHOULD_RELOAD_FAVOURITES",
    setCurrentlyShowingTeaserboxes = "UI_SET_CURRENTLY_SHOWING_TEASERBOXES",
    SetResponsiveMenuVisible = "UI_SET_RESPONSIVE_MENU_OPEN",
    SetResponsiveProfileVisible = "UI_SET_RESPONSIVE_PROFILE_OPEN",
    SetResponsiveSearchVisible = "UI_SET_RESPONSIVE_SEARCH_OPEN",
    SetResponsiveShortcutsVisible = "UI_SET_RESPONSIVE_SHORTCUTS_OPEN",
    SetResponsiveSubmenuVisible = "UI_SET_RESPONSIVE_SUBMENU_OPEN",
    IncrementStartViewKey = "UI_INCREMENT_START_VIEW_KEY",
    SetFocusCommentFieldForUberId = "UI_SET_FOCUS_COMMENT_FIELD_FOR_UBER_ID",
    SetFormFooterBarVisible = "UI_SET_FORM_FOOTER_BAR_VISIBLE",
    SetFormFooterBarTop = "UI_SET_FORM_FOOTER_BAR_TOP",
    SetIsInTeamsApp = "UI_SET_IS_IN_TEAMS_APP",
    SetAppMode = "UI_SET_APP_MODE",
    SetVisage2FullscreenMode = "UI_SET_VISAGE_2_FULLSCREEN_MODE",
    SetVisage2FullscreenHeaderVisible = "UI_SET_VISAGE_2_FULLSCREEN_HEADER_VISIBLE",
    SetGlobalBreadcrumbs = "UI_SET_GLOBAL_BREADCRUMBS",
    AddUploads = "UI_ADD_UPLOADS",
    ClearUploads = "UI_CLEAR_UPLOADS",
    UpdateUpload = "UI_UPDATE_UPLOAD",
    RemoveUpload = "UI_REMOVE_UPLOAD",
    ToggleGreyBackground = "UI_TOGGLE_GREY_BACKGROUND",
    SetPublic360SelectedUnitLevel1 = "UI_SET_PUBLIC360_UNIT_LEVEL1",
    SetPublic360SelectedUnitLevel2 = "UI_SET_PUBLIC360_UNIT_LEVEL2",
    SetOverlayId = "UI_SET_OVERLAY_ID",
    SetDisplayHeaderSearch = "UI_SET_DISPLAY_HEADER_SEARCH",
    SetUseDocumentWidthMode = "UI_SET_USE_DOCUMENT_WIDTH_MODE",
    SetUseNarrowFormWidthMode = "UI_SET_USE_NARROW_FORM_WIDTH_MODE",
    SetDisableWrapperBackground = "UI_SET_DISABLE_WRAPPER_BACKGROUND",
    SetReactionPickerConfig = "UI_SET_REACTION_PICKER_CONFIG",
    SetStartPageBuilderMode = "UI_SET_START_PAGE_BUILDER_MODE",
    SetHeaderSearchBarMode = "UI_SET_HEADER_SEARCH_BAR_MODE",
    SetAdminNoPadding = "UI_SET_ADMIN_NO_PADDING",
}

export interface ISetHeaderSearchBarModeAction extends Action {
    mode: SpintrTypes.HeaderSearchBarMode;
    type: UiActionTypes.SetHeaderSearchBarMode;
}

export interface IToggleGreyBackground extends Action {
    displayGreyBackground: boolean;
    type: UiActionTypes.ToggleGreyBackground;
}

export interface ISidebarExpandedAction extends Action {
    isExpanded: boolean;
    type: UiActionTypes.SetSidebarExpanded;
}

export interface IIsSmallViewModeAction extends Action {
    isSmallViewMode: boolean;
    type: UiActionTypes.SetIsSmallViewMode;
}

export interface IIncrementStartViewKey extends Action {
    type: UiActionTypes.IncrementStartViewKey;
}

export interface IAddUploads extends Action {
    uploads: any[],
    type: UiActionTypes.AddUploads
}

export interface IClearUploads extends Action {
    type: UiActionTypes.ClearUploads
}

export interface IRemoveUpload extends Action {
    uploadId: any,
    type: UiActionTypes.RemoveUpload
}

export interface IUpdateUpload extends Action {
    upload: any,
    type: UiActionTypes.UpdateUpload
}

export interface ISetIsTinyInModalAction extends Action {
    isTinyInModal: boolean;
    type: UiActionTypes.SetIsTinyInModal;
}

export interface ISetViewModeAction extends Action {
    viewMode: Spintr.ViewMode;
    type: UiActionTypes.SetViewMode;
}

export interface IActiveActionMenuAction extends Action {
    activeActionMenu: string;
    type: UiActionTypes.SET_ACTIVE_ACTION_MENU;
}

export interface ISetDisplayAsOfflineInChatAction extends Action {
    displayAsOfflineInChat: boolean;
    type: UiActionTypes.SetDisplayAsOfflineInChat;
}

export interface ISetActiveSidebarTabAction extends Action {
    activeSidebarTab: number;
    type: UiActionTypes.SetActiveSidebarTab;
}

export interface ISetResponsiveMenuVisibleAction extends Action {
    type: UiActionTypes.SetResponsiveMenuVisible;
    visible: boolean;
}

export interface ISetResponsiveProfileVisibleAction extends Action {
    type: UiActionTypes.SetResponsiveProfileVisible;
    visible: boolean;
}

export interface ISetResponsiveShortcutsVisibleAction extends Action{
    type: UiActionTypes.SetResponsiveShortcutsVisible;
    visible:boolean;
}

export interface ISetResponsiveSearchVisibleAction extends Action {
    type: UiActionTypes.SetResponsiveSearchVisible;
    visible: boolean;
}

export interface ISetResponsiveSubmenuVisibleAction extends Action {
    type: UiActionTypes.SetResponsiveSubmenuVisible;
    visible: boolean;
}

export interface ISetFormFooterBarVisibleAction extends Action {
    type: UiActionTypes.SetFormFooterBarVisible;
    visible: boolean;
}

export interface ISetFormFooterBarTopAction extends Action {
    type: UiActionTypes.SetFormFooterBarTop;
    top: boolean;
}

export interface IShouldReloadFavourites extends Action {
    shouldReload: boolean;
    type: UiActionTypes.SetShouldReloadFavourites;
}

export interface ISetCurrentlyShowingTeaserboxes extends Action {
    teaserboxes: number[];
    type: UiActionTypes.setCurrentlyShowingTeaserboxes;
}

export interface ISetFocusCommentFieldForUberId extends Action {
    focusCommentFieldForUberId: number;
    type: UiActionTypes.SetFocusCommentFieldForUberId;
}

export interface ISetIsInTeamsApp extends Action {
    isInTeamsApp: boolean;
    type: UiActionTypes.SetIsInTeamsApp;
}

export interface ISetAppMode extends Action {
    appMode: boolean;
    type: UiActionTypes.SetAppMode;
}

export interface ISetVisage2FullscreenMode extends Action {
    visage2FullscreenMode: boolean;
    type: UiActionTypes.SetVisage2FullscreenMode;
}

export interface ISetVisage2FullscreenHeaderVisible extends Action {
    visage2FullscreenHeaderVisible: boolean;
    type: UiActionTypes.SetVisage2FullscreenHeaderVisible;
}

export interface ISetGlobalBreadcrumbs extends Action {
    value: Spintr.IBreadcrumbItem[];
    type: UiActionTypes.SetGlobalBreadcrumbs
}

export interface ISetPublic360SelectedUnitLevel1 extends Action {
    unit: any;
    type: UiActionTypes.SetPublic360SelectedUnitLevel1;
}

export interface ISetPublic360SelectedUnitLevel2 extends Action {
    unit: any;
    type: UiActionTypes.SetPublic360SelectedUnitLevel2;
}

export interface ISetOverlayId extends Action {
    value: string;
    type: UiActionTypes.SetOverlayId
}

export interface ISetDisplayHeaderSearch extends Action {
    value: boolean;
    type: UiActionTypes.SetDisplayHeaderSearch
}

export interface ISetUseDocumentWidthMode extends Action {
    value: boolean;
    type: UiActionTypes.SetUseDocumentWidthMode
}

export interface ISetUseNarrowFormWidthMode extends Action {
    value: boolean;
    type: UiActionTypes.SetUseNarrowFormWidthMode
}

export interface ISetDisableWrapperBackground extends Action {
    value: boolean;
    type: UiActionTypes.SetDisableWrapperBackground
}

export interface ISetReactionPickerConfig extends Action {
    value: IReactionPickerConfig;
    type: UiActionTypes.SetReactionPickerConfig
}

export interface ISetStartPageBuilderMode extends Action {
    value: SpintrTypes.StartPageBuilderMode;
    type: UiActionTypes.SetStartPageBuilderMode
}

export interface ISetAdminNoPadding extends Action {
    value: boolean;
    type: UiActionTypes.SetAdminNoPadding
}

export type UiAction 
    = ISetHeaderSearchBarModeAction
    | ISidebarExpandedAction
    | IIsSmallViewModeAction
    | IIncrementStartViewKey
    | ISetViewModeAction
    | IActiveActionMenuAction
    | ISetDisplayAsOfflineInChatAction
    | ISetActiveSidebarTabAction
    | ISetResponsiveMenuVisibleAction
    | ISetResponsiveProfileVisibleAction
    | ISetResponsiveShortcutsVisibleAction
    | ISetResponsiveSearchVisibleAction
    | ISetResponsiveSubmenuVisibleAction
    | ISetFormFooterBarVisibleAction
    | ISetFormFooterBarTopAction
    | IShouldReloadFavourites
    | ISetCurrentlyShowingTeaserboxes
    | ISetIsTinyInModalAction
    | ISetFocusCommentFieldForUberId
    | ISetIsInTeamsApp
    | ISetAppMode
    | ISetVisage2FullscreenMode
    | ISetVisage2FullscreenHeaderVisible
    | ISetGlobalBreadcrumbs
    | IClearUploads
    | IAddUploads
    | IUpdateUpload
    | IRemoveUpload
    | ISetPublic360SelectedUnitLevel1
    | ISetPublic360SelectedUnitLevel2
    | ISetOverlayId
    | ISetDisplayHeaderSearch
    | ISetUseDocumentWidthMode
    | ISetUseNarrowFormWidthMode
    | ISetDisableWrapperBackground
    | ISetReactionPickerConfig
    | ISetStartPageBuilderMode
    | IToggleGreyBackground
    | ISetAdminNoPadding;
