import moment from "moment";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { Component, createRef, RefObject } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { localize } from "src/l10n";
import { UnstyledButton } from "src/ui";
import { FillHeight, Label, Scrollable, SpintrUser } from "src/ui/components";
import { Style } from "src/ui/helpers";
import api from "src/spintr/SpintrApi";
import { markAsRead, MarkAsReadHandler } from "src/chat";
import { clearUnread } from "../group-actions";

interface IDispatchProps {
    markAsRead: MarkAsReadHandler;
    dispatch: any;
}

interface IOwnProps {
    conversationId: number;
    history: any;
}

interface IStateProps {
    conversations?: any;
    hasFormFooterBar?: boolean;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

interface IState {
    items: any[];
    isLoading: boolean;
    hasMore: boolean;
}

class GroupHistoryView extends Component<Props, IState> {
    protected heightRef: RefObject<FillHeight>;

    constructor(props) {
        super(props);

        this.heightRef = createRef();

        this.state = {
            items: [],
            isLoading: false,
            hasMore: true
        };
    }

    componentDidMount() {
        this.fetch();

        this.props.markAsRead(this.props.conversationId, null, null, true);
    }

    componentDidUpdate(prevProps) {
        const prevConversation = !!prevProps &&
            !!prevProps.conversations ?
                prevProps.conversations.find(c => c.id === this.props.conversationId) :
                null;

        const newConversation = !!this.props &&
            !!this.props.conversations ?
                this.props.conversations.find(c => c.id === this.props.conversationId) :
                null;

        let doReFetch = false;

        if (!prevConversation &&
            !!newConversation) {
            doReFetch = true;
        }

        if (!!prevConversation &&
            !!newConversation) {
            const prevLastMessage = prevConversation.lastMessage;
            const newLastMessage = newConversation.lastMessage;

            if (!!prevLastMessage &&
                !!newLastMessage &&
                prevLastMessage.id !== newLastMessage.id &&
                newLastMessage.isSystem) {
                doReFetch = true;
            }
        }

        if (doReFetch) {
            this.setState({
                items: [],
                isLoading: false,
                hasMore: true
            }, () => {
                this.fetch();
            });
        }
    }

    fetch() {
        if (this.state.isLoading ||
            !this.state.hasMore) {
            return;
        }

        const take = 30;
        let maxId = 0;

        if (this.state.items.length > 0) {
            maxId = this.state.items[this.state.items.length -1].id;
        }

        let url = "/api/v1/messages?conversationId=" +
            this.props.conversationId +
            "&skip=" +
            this.state.items.length +
            "&take=" +
            take +
            "&maxId=" +
            maxId +
            "&fetchType=3";

        this.setState({
            isLoading: true
        }, () => {
            api.get(url).then((response) => {
                this.setState({
                    isLoading: false,
                    hasMore: response.data.length === take,
                    items: [
                        ...this.state.items,
                        ...response.data
                    ]
                });
            }).catch(() => {});
        });
    }

    render() {
        return (
            <div className="GroupHistoryView">
                <Scrollable
                    displayLoader={this.state.isLoading}
                    onEndReached={() => {
                        console.log("onEndReached");
                        this.fetch();
                    }}>
                        <div className="GroupHistoryView-inner">
                            <Label weight="medium">{localize("Aktivitetslogg")}</Label>
                            {
                                !this.state.isLoading &&
                                this.state.items.length === 0 ?
                                    <Label role="heading" as="div" size="small-1" style={{
                                        margin: Style.getSpacing(3),
                                        textAlign: "center"
                                    }}>
                                        {
                                            localize("IngaPoster")
                                        }
                                    </Label> :
                                    null
                            }
                            {
                                this.state.items.map((item, index) => {
                                    return (
                                        <UnstyledButton key={item.id + "_" + index} onClick={() => {
                                            this.props.history.push({
                                                pathname: `/goto/${item.object.id}`,
                                            });
                                        }}>
                                            <div className="GroupHistoryViewItem">
                                                <div className="GroupHistoryViewItem-inner">
                                                    <div className="GroupHistoryViewItem-left">
                                                        <SpintrUser
                                                            id={item.user.id}
                                                            name={item.user.name}
                                                            imageUrl={item.user.imageUrl}
                                                            hideText={true}
                                                            personalName={true}
                                                        />
                                                    </div>
                                                    <div className="GroupHistoryViewItem-right">
                                                        <Label color="dark-grey" size="body-2" weight="medium" as="h3">
                                                            {item.user.name}
                                                        </Label>
                                                        <Label as="p" size="body-3" className="general-row-break">
                                                            {
                                                                localize(item.text) + ((item.object && item.object.name) ? (": " + item.object.name) : "")
                                                            }
                                                        </Label>
                                                        <TooltipHost
                                                            content={moment(item.date).format('LLLL')}
                                                        >
                                                            <Label as="span" size="small-2" color="dark-grey">
                                                                {
                                                                    moment(item.date).fromNow()
                                                                }
                                                            </Label>
                                                        </TooltipHost>
                                                    </div>
                                                </div>
                                            </div>
                                        </UnstyledButton>
                                    )
                                })
                            }
                        </div>
                </Scrollable>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    conversations: state.chat.conversations.items.filter(c => c.type === 3),
    hasFormFooterBar: state.ui.formFooterBarVisible
});

const mapDispatchToProps = (dispatch) => ({
    markAsRead,
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupHistoryView);
