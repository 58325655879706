import React, { FormEvent, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { CaptionBody, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from "src/components";
import { ProductFormDrawerProps, ProductFormDrawerState, ProductFormDrawerStateProps as StateProps } from "./ProductFormDrawer.types";
import { localize } from "src/l10n";
import { Checkbox, Icon, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { FormControl } from "src/ui/components/Forms";
import { Product, ProductModel, UploadItem, UploadedImage } from "src/products/types";
import { uniqueId } from "src/utils";
import { AxiosRequestConfig } from "axios";
import { createProductAsync, updateProductAsync } from "src/products/productsApi";
import { useSelector } from "react-redux";
import api from "src/spintr/SpintrApi";
import { DropZone } from "src/spintr/components";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ProductImageUpload } from "../ProductImageUpload";
import { FieldValidator, fieldValidation, invalidFormat, maxLength, minLength, oneOf, patterns, regex, required, validateModel } from "src/utils/validation";
import SpintrLoader from "src/ui/components/Loader";
import { UnstyledButton } from "src/ui";
import { image } from "d3";
import { SpintrTypes } from "src/typings";
import { randexp } from "randexp";

function getInitialModel(product?: Product, articleId?: string | undefined): ProductModel {
    return {
        name: product?.name || "",
        articleId: product?.articleId || articleId || "",
        description: product?.description || "", 
        images: product?.images?.map((image) => ({
            id: image.id,
            fileName: "",
            thumbnailUrl: image.imageUrl,
            ticket: "",
            remove: false,
        })) || [],
        metadata: product?.metadata?.map((metadata) => ({
            fieldId: metadata.fieldId,
            value: metadata.value,
        })) || [],
    };
}

function ProductFormDrawer({ initialArticleId, product, onDismiss, open }: ProductFormDrawerProps): ReactElement {
    const productId = product?.id
    const creatingNew = !productId;
    const { isAdministrator, articleIdPatterns } = useSelector<Spintr.AppState, StateProps>(
        (state) => ({
            isAdministrator: state.profile.active.roles?.includes("administrators"),
            articleIdPatterns: state.instance.get("articleIdPatterns") as string[],
        }),
    );

    const [model, setModel] = useState<ProductModel>(
        () => getInitialModel(product, initialArticleId),
    );
    const [viewState, setViewState] = useState<ProductFormDrawerState>(() => {
        const primaryImageId = model.images
            .filter((image) => image.thumbnailUrl === product?.imageUrl)
            .map((image) => image.id)
            .find((id) => !!id);

        return {
            errors: {},
            isLoading: false,
            primaryImageId: primaryImageId ?? model.images.map((image) => image.id).find((id) => !!id),
            linkExisting: true,
        };
    });
    
    const { isLoading, metadataFields, modelState } = viewState;
    const validationRules = useMemo<FieldValidator[]>(() => {
        const articleIdRules = [
            required(),
            minLength(3),
            maxLength(64),
        ];

        const articleIdRegexes = (articleIdPatterns || [])
            .map((pattern: string) => {
                try {
                    return new RegExp(`^${pattern}$`, "gmi");
                } catch (_) {
                    return null;
                }
            })
            .filter((pattern: RegExp | null) => pattern !== null)

        if (articleIdRegexes.length > 0) {
            articleIdRules.push(oneOf(articleIdRegexes.map(
                (pattern) => regex(pattern, (fieldName) => invalidFormat(fieldName) + ` (${randexp(pattern, "gmi")})`),
            )));
        }

        return [
            fieldValidation("name", localize("Namn"),
                required(),
                minLength(3),
                maxLength(64)),
            fieldValidation("articleId", localize("ARTICLE_ID"), ...articleIdRules),
            ...(metadataFields || [])
                .filter((field) => field.isRequired)
                .map((field) => fieldValidation(field.id, field.name, required())),
        ];
    }, [metadataFields, articleIdPatterns]);

    useEffect(() => {
        if (!modelState) {
            return;
        }

        const baseModel = {
            name: model.name,
            articleId: model.articleId
        };

        const result = validateModel(
            (metadataFields || [])
                .map((field) => model.metadata.find((input) => input.fieldId === field.id) || {
                    fieldId: field.id,
                    value: "",
                })
                .reduce((acc, input) => {
                    acc[input.fieldId] = input.value;

                    return acc;
                }, baseModel),
            validationRules
        );

        setViewState((prevState) => ({
            ...prevState,
            errors: Object.keys(result)
                .filter((resultKey) => modelState[resultKey]?.isDirty)
                .reduce((acc, resultKey) => {
                    acc[resultKey] = result[resultKey];
                    return acc;
                }, {}),
        }));
    }, [validationRules, model, metadataFields, modelState, setViewState]);

    const onTextFieldChanged = useCallback(
        (event: FormEvent<HTMLInputElement>) => {
            const { name, value } = event.target as HTMLInputElement;

            const metadataField = metadataFields?.find((field) => field.id === name);

            setModel((prevModel) => {
                if (!metadataField) {
                    return {
                        ...prevModel,
                        [name]: value,
                    };
                }

                return {
                    ...prevModel,
                    metadata: prevModel.metadata.some((input) => input.fieldId === metadataField.id)
                        ? prevModel.metadata.map((input) => {
                            if (input.fieldId !== metadataField.id) {
                                return input;
                            }
                            
                            return {
                                fieldId: metadataField.id,
                                value,
                            };
                        })
                        : [...prevModel.metadata, { fieldId: metadataField.id, value }],
                };
            });

            setViewState((prevState) => ({
                ...prevState,
                modelState: {
                    ...prevState.modelState || {},
                    [name]: {
                        isDirty: true,
                    },
                }
            }));
        },
        [setModel, setViewState, metadataFields],
    );

    const onFilesSelected = useCallback(async (fileList: File[]/* | FileList*/) => {
        const files = [...fileList];
        if (files.length === 0) {
            return;
        }

        const promises: UploadItem[] = files
            .filter((file) => file.type.indexOf("image/") === 0)
            .map<UploadItem>((file) => {
                const ticket = new Date().getTime().toString() + uniqueId();
                
                const body = new FormData();
                body.append("type", "1");
                body.append("file", file, file.name);
                body.append("thumbnailSize", "natural");
                body.append("ticket", ticket);
    
                const config: AxiosRequestConfig = {
                    onUploadProgress: (progressEvent) => {
                        console.log(progressEvent);
                    },
                };

                return {
                    fileName: file.name,
                    promise: api.post<Spintr.IImageUploadResponse>(
                        "/api/v1/uploads",
                        body,
                        config,
                    ).then((response) => response.data),
                }
            })

        const images = await Promise.all(promises.map<Promise<UploadedImage>>(async (item) => {
            const response = await item.promise;

            return {
                id: 0 - parseInt(uniqueId(), 10),
                fileName: item.fileName,
                remove: false,
                ...response.Result,
            };
        }))

        setModel((prevModel) => ({
            ...prevModel,
            images: [...prevModel.images, ...images],
        }));
    }, [setModel]);

    const { linkExisting, errors, primaryImageId } = viewState;
    const onFormSubmitted = useCallback(
        async (event?: FormEvent | undefined) => {
            if (event?.preventDefault) {
                event.preventDefault();
            }

            if (isLoading || Object.keys(errors).length > 0) {
                return;
            }

            setViewState((prevState) => ({
                ...prevState,
                isLoading: true,
            }));

            const adjustedModel = { ...model };
            if (primaryImageId) {
                const primaryImage = adjustedModel.images.find((image) => image.id === primaryImageId);

                if (primaryImage) {
                    // Shift primary image to the front of the array
                    adjustedModel.images = adjustedModel.images.filter((image) => image.id !== primaryImageId);
                    adjustedModel.images.unshift(primaryImage);
                }
            }

            try {
                const promise = creatingNew
                    ? createProductAsync(adjustedModel, linkExisting)
                    : updateProductAsync(productId, {
                        ...adjustedModel,
                        status: SpintrTypes.ContentStatus.Published,
                    });

                const product = await promise;

                onDismiss(product);
            } catch (err) {
                const errorModel = err as Spintr.IErrorResponse;
                if (errorModel.errors) {
                    // We can validate everything but the article ID on the client side
                    // so the only thing that realistically can go wrong is the article ID
                    // being taken.
                    const articleIdError = errorModel.errors.find(
                        (error) => error.fieldName === "articleId",
                    );

                    if (articleIdError) {
                        setViewState((prevState) => ({
                            ...prevState,
                            errors: {
                                ...errors,
                                "articleId": localize(articleIdError.message),
                            },
                            isLoading: false,
                        }));
                        return;
                    }
                }
                
                setViewState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        },
        [model, setViewState, isLoading, productId, creatingNew, onDismiss, linkExisting, errors, primaryImageId],
    );

    const onDrawerDismiss = useCallback(
        () => {
            if (!onDismiss) {
                return;
            }

            setModel(() => getInitialModel(product));

            onDismiss();
        },
        [onDismiss, product],
    );

    const onSubmitClicked = useCallback(() => onFormSubmitted(), [onFormSubmitted]);

    useEffect(() => {
        if (!isAdministrator) {
            return;
        }

        api.get<Spintr.IMetadataField[]>("/api/v1/products/metadata")
            .then((response) => setViewState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    metadataFields: response.data,
            })))
            .catch((error) => {
                console.error(error);
            });
    }, [setViewState, isAdministrator]);

    const onLinkExistingChanged = useCallback(
        (event?: FormEvent<HTMLInputElement | HTMLElement> | undefined, checked?: boolean | undefined) => {
            if (!event) {
                return;
            }

            setViewState((prevState) => ({
                ...prevState,
                linkExisting: !!checked,
            }));
        },
        [setViewState],
    );

    const linkCheckbox = creatingNew ? (
        <FormControl label={localize("LINK_PRODUCT_TO_POSTS")}>
            <Checkbox
                checked={viewState.linkExisting || false}
                label={localize("LINK_PRODUCT_TO_POSTS_LABEL")}
                name="linkExisting"
                onChange={onLinkExistingChanged}
            />
        </FormControl>
    ) : null;

    const setPrimaryImage = useCallback((image: UploadedImage) => setViewState((prevState) => ({    
        ...prevState,
        primaryImageId: image.id,
    })), [setViewState]);

    const onDeletingImage = useCallback((image: UploadedImage) => setModel((prevState) => ({
        ...prevState,
        images: prevState.images
            .map((img) => img.id !== image.id
                ? img
                : ({
                    ...img,
                    remove: true,
                })),
    })), [setModel]);

    const innerForm = isLoading ? <SpintrLoader /> : (
        <form method="POST" action="/api/v1/products" onSubmit={onFormSubmitted}>
            <FormControl>
                <TextField
                    aria-required={true}
                    className="textField"
                    label={localize("Namn")}
                    name="name"
                    onChange={onTextFieldChanged}
                    required={true}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={viewState.errors["name"]}
                    value={model.name}
                />
            </FormControl>
            <FormControl>
                <TextField
                    aria-required={true}
                    className="textField"
                    label={localize("ARTICLE_ID")}
                    name="articleId"
                    onChange={onTextFieldChanged}
                    required={true}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={viewState.errors["articleId"]}
                    value={model.articleId}
                />
            </FormControl>

            <FormControl>
                <TextField
                    aria-required={true}
                    className="textField"
                    label={localize("Beskrivning")}
                    multiline={true}
                    name="description"
                    onChange={onTextFieldChanged}
                    required={true}
                    validateOnFocusIn={true}
                    validateOnFocusOut={true}
                    errorMessage={viewState.errors["description"]}
                    value={model.description}
                />
            </FormControl>
            {(metadataFields || []).map((field) => (
                <FormControl key={field.id}>
                    <TextField
                        aria-required={field.isRequired}
                        className="textField"
                        label={field.name}
                        name={field.id}
                        onChange={onTextFieldChanged}
                        required={field.isRequired}
                        validateOnFocusIn={true}
                        validateOnFocusOut={true}
                        errorMessage={viewState.errors[field.id]}
                        value={model.metadata.find((input) => input.fieldId === field.id)?.value || ""}
                    />
                </FormControl>
            ))}
            <FormControl label={localize("Bilder")}>
                <DropZone
                    className="image-drop-zone"
                    fileTypesString="image/*"
                    isMultiple={true}
                    onFilesSelected={onFilesSelected}
                >
                    <Stack className="image-stack" horizontal={true}>
                        {model.images
                            .filter((image) => !image.remove)
                            .map((image) => (
                                <StackItem
                                    className="product-image-container"
                                    key={image.fileName || image.id}
                                >
                                    <ProductImageUpload
                                        image={image}
                                        isPrimary={image.id === viewState.primaryImageId}
                                        onMarkAsPrimary={setPrimaryImage}
                                        onDelete={onDeletingImage}
                                    />
                                </StackItem>
                            ))
                        }
                        <StackItem className="product-image-container upload-button">
                            <Visage2Icon icon="gallery-add" color="grey" />
                            <CaptionBody
                                color="contentLight"
                                weight="medium"
                            >
                                {localize("ADD_PHOTO")} {localize("OR_DRAG_DROP")}
                            </CaptionBody>
                        </StackItem>
                    </Stack>
                </DropZone>
            </FormControl>
            {linkCheckbox}
        </form>
    );

    const buttons = isLoading ? null : (
        <div className="actions">
            <PrimaryButton
                onClick={onDrawerDismiss}
            >
                {localize("Avbryt")}
            </PrimaryButton>
            <PrimaryButton
                onClick={onSubmitClicked}
                disabled={Object.keys(errors).length > 0}
            >
                {localize("Spara")}
            </PrimaryButton>
        </div>
    );

    return (
        <Drawer
            className="ProductFormDrawer"
            open={open}
            position="end"
            size="medium"
        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <UnstyledButton
                            className="close-button"
                            onClick={onDrawerDismiss}
                            title={localize("Stang")}
                        >
                            <Icon iconName="ChromeClose" />
                        </UnstyledButton>
                    }
                >
                    {localize(creatingNew ? "ADD_NEW_PRODUCT" : "EDIT_PRODUCT")}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                {innerForm}
            </DrawerBody>
            <DrawerFooter>
                {buttons}
            </DrawerFooter>
        </Drawer>
    );
}

export default ProductFormDrawer;
